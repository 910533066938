import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The built-in `Decimal` scalar type. */
  Decimal: any;
  UUID: string;
};

export type Aggregate = {
  __typename?: 'Aggregate';
  cypherCode: Scalars['String'];
  externalId: Scalars['String'];
  id: Scalars['UUID'];
  logic?: Maybe<AggregationLogic>;
  name: Scalars['String'];
  objectClassType: Scalars['String'];
  objectName: Scalars['String'];
  output?: Maybe<Output>;
  tenantId?: Maybe<Scalars['UUID']>;
};

export type AggregatedData = {
  __typename?: 'AggregatedData';
  timestamp: Scalars['DateTime'];
  value: Scalars['Float'];
  valueCount: Scalars['Float'];
  valueMax: Scalars['Float'];
  valueMin: Scalars['Float'];
};

export enum AggregationIntervals {
  D_01 = 'D_01',
  D_02 = 'D_02',
  D_03 = 'D_03',
  D_04 = 'D_04',
  D_05 = 'D_05',
  D_06 = 'D_06',
  D_07 = 'D_07',
  H_01 = 'H_01',
  H_02 = 'H_02',
  H_03 = 'H_03',
  H_04 = 'H_04',
  H_06 = 'H_06',
  H_08 = 'H_08',
  H_12 = 'H_12',
  Ms_500 = 'MS_500',
  M_01 = 'M_01',
  M_02 = 'M_02',
  M_03 = 'M_03',
  M_04 = 'M_04',
  M_05 = 'M_05',
  M_06 = 'M_06',
  M_10 = 'M_10',
  M_12 = 'M_12',
  M_15 = 'M_15',
  M_20 = 'M_20',
  M_30 = 'M_30',
  None = 'NONE',
  S_01 = 'S_01',
  S_02 = 'S_02',
  S_03 = 'S_03',
  S_04 = 'S_04',
  S_05 = 'S_05',
  S_06 = 'S_06',
  S_10 = 'S_10',
  S_12 = 'S_12',
  S_15 = 'S_15',
  S_20 = 'S_20',
  S_30 = 'S_30'
}

export type AggregationLogic = {
  __typename?: 'AggregationLogic';
  aggregationLogicType: AggregationLogicType;
  aggregationType?: Maybe<AggregationType>;
  cypherCode: Scalars['String'];
  id: Scalars['UUID'];
  isValid: Scalars['Boolean'];
  logic?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  objectClassType: Scalars['String'];
  objectName: Scalars['String'];
  tenantId?: Maybe<Scalars['UUID']>;
  timeWindow?: Maybe<TimeWindow>;
  variables: Array<Variable>;
};

export enum AggregationLogicType {
  Custom = 'CUSTOM',
  RoomAirTwAvgPpm = 'ROOM_AIR_TW_AVG_PPM',
  RoomHumidityDeviation = 'ROOM_HUMIDITY_DEVIATION',
  RoomHumidityTwAvgDeviation = 'ROOM_HUMIDITY_TW_AVG_DEVIATION',
  RoomHumidityTwAvgHumidity = 'ROOM_HUMIDITY_TW_AVG_HUMIDITY',
  RoomTempDeviation = 'ROOM_TEMP_DEVIATION',
  RoomTempTwAvgDeviation = 'ROOM_TEMP_TW_AVG_DEVIATION',
  RoomTempTwAvgTemp = 'ROOM_TEMP_TW_AVG_TEMP',
  SensorStabilityDeviation = 'SENSOR_STABILITY_DEVIATION',
  SensorStabilityTwAvgDeviation = 'SENSOR_STABILITY_TW_AVG_DEVIATION',
  SystemPerformanceDeviation = 'SYSTEM_PERFORMANCE_DEVIATION',
  SystemPerformanceTwAvgDeviation = 'SYSTEM_PERFORMANCE_TW_AVG_DEVIATION'
}

export enum AggregationType {
  None = 'NONE',
  OperatingTimeBinary = 'OPERATING_TIME_BINARY',
  TimeWeightedAvg = 'TIME_WEIGHTED_AVG'
}

export enum AggregationTypes {
  Avg = 'AVG',
  Sum = 'SUM'
}

export enum AlarmState {
  Active = 'ACTIVE',
  Closed = 'CLOSED'
}

export type AlarmStateHistoryItem = {
  __typename?: 'AlarmStateHistoryItem';
  state: AlarmState;
  timestamp: Scalars['DateTime'];
};

export enum AlarmType {
  Alarm = 'ALARM',
  SystemError = 'SYSTEM_ERROR',
  Warning = 'WARNING'
}

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type Attribute = {
  __typename?: 'Attribute';
  dataType: DataTypes;
  description?: Maybe<Scalars['String']>;
  deviceAttributeValues: Array<DeviceAttributeValue>;
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  mappingAttributeDeviceModelAttributeSets: Array<MappingAttributeDeviceModelAttributeSet>;
  name: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  tenantId?: Maybe<Scalars['UUID']>;
};


export type AttributeDeviceAttributeValuesArgs = {
  order?: InputMaybe<Array<DeviceAttributeValueSortInput>>;
  where?: InputMaybe<DeviceAttributeValueFilterInput>;
};


export type AttributeMappingAttributeDeviceModelAttributeSetsArgs = {
  order?: InputMaybe<Array<MappingAttributeDeviceModelAttributeSetSortInput>>;
  where?: InputMaybe<MappingAttributeDeviceModelAttributeSetFilterInput>;
};

export type AttributeCreateType = {
  dataType: DataTypes;
  description?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  required?: InputMaybe<Scalars['Boolean']>;
};

export type AttributeDefinition = {
  __typename?: 'AttributeDefinition';
  dataType: DataTypes;
  description?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
};

export type AttributeDefinitionFilterInput = {
  and?: InputMaybe<Array<AttributeDefinitionFilterInput>>;
  dataType?: InputMaybe<DataTypesOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AttributeDefinitionFilterInput>>;
  required?: InputMaybe<BooleanOperationFilterInput>;
};

export type AttributeFilterInput = {
  and?: InputMaybe<Array<AttributeFilterInput>>;
  attributeId?: InputMaybe<UuidOperationFilterInput>;
  dataType?: InputMaybe<DataTypesOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  deviceAttributeValues?: InputMaybe<ListFilterInputTypeOfDeviceAttributeValueFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingAttributeDeviceModelAttributeSets?: InputMaybe<ListFilterInputTypeOfMappingAttributeDeviceModelAttributeSetFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AttributeFilterInput>>;
  required?: InputMaybe<BooleanOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type AttributeIdAttributeValueInputType = {
  attributeId?: InputMaybe<Scalars['UUID']>;
  value?: InputMaybe<Scalars['String']>;
};

export type AttributeSetNullInputType = {
  dataType?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  deviceAttributeValues?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingAttributeDeviceModelAttributeSets?: InputMaybe<Scalars['Boolean']>;
  required?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type AttributeSortInput = {
  attributeId?: InputMaybe<SortEnumType>;
  dataType?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  required?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type AttributeUpdateType = {
  dataType?: InputMaybe<DataTypes>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
};

export type AttributeWithValue = {
  __typename?: 'AttributeWithValue';
  attribute?: Maybe<Attribute>;
  value?: Maybe<Scalars['String']>;
};

export type AttributesByBuildings = {
  __typename?: 'AttributesByBuildings';
  buildingId?: Maybe<Scalars['UUID']>;
  deviceAttributes?: Maybe<Array<AttributeDefinition>>;
};

/** A segment of a collection. */
export type AttributesByBuildingsCollectionSegment = {
  __typename?: 'AttributesByBuildingsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<AttributesByBuildings>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type AttributesByBuildingsFilterInput = {
  and?: InputMaybe<Array<AttributesByBuildingsFilterInput>>;
  buildingId?: InputMaybe<UuidOperationFilterInput>;
  deviceAttributes?: InputMaybe<ListFilterInputTypeOfAttributeDefinitionFilterInput>;
  or?: InputMaybe<Array<AttributesByBuildingsFilterInput>>;
};

export type AttributesByBuildingsSortInput = {
  buildingId?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type AttributesCollectionSegment = {
  __typename?: 'AttributesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Attribute>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type AuditLog = {
  __typename?: 'AuditLog';
  action?: Maybe<Scalars['String']>;
  changeDate: Scalars['DateTime'];
  entity?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  service?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['UUID']>;
  userId: Scalars['UUID'];
};

export type AuditLogCreateType = {
  action?: InputMaybe<Scalars['String']>;
  changeDate: Scalars['DateTime'];
  entity?: InputMaybe<Scalars['String']>;
  entityType?: InputMaybe<Scalars['String']>;
  service?: InputMaybe<Scalars['String']>;
  userId: Scalars['UUID'];
};

export type AuditLogFilterInput = {
  action?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<AuditLogFilterInput>>;
  auditLogId?: InputMaybe<UuidOperationFilterInput>;
  changeDate?: InputMaybe<DateTimeOperationFilterInput>;
  entity?: InputMaybe<StringOperationFilterInput>;
  entityType?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<AuditLogFilterInput>>;
  service?: InputMaybe<StringOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  userId?: InputMaybe<UuidOperationFilterInput>;
};

export type AuditLogSortInput = {
  action?: InputMaybe<SortEnumType>;
  auditLogId?: InputMaybe<SortEnumType>;
  changeDate?: InputMaybe<SortEnumType>;
  entity?: InputMaybe<SortEnumType>;
  entityType?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  service?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  userId?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type AuditLogsCollectionSegment = {
  __typename?: 'AuditLogsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<AuditLog>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<Scalars['Boolean']>;
};

/** I am a generated type! */
export type Building = {
  __typename?: 'Building';
  description?: Maybe<Scalars['String']>;
  dimension?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  image?: Maybe<Image>;
  imageId?: Maybe<Scalars['UUID']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['UUID']>;
  mappingDeviceBuildings?: Maybe<Array<Maybe<MappingDeviceBuilding>>>;
  name: Scalars['String'];
  site: Site;
  siteId: Scalars['UUID'];
  storeys: Array<Storey>;
  tenantId?: Maybe<Scalars['UUID']>;
  weather?: Maybe<Weather>;
};


/** I am a generated type! */
export type BuildingStoreysArgs = {
  order?: InputMaybe<Array<StoreySortInput>>;
  where?: InputMaybe<StoreyFilterInput>;
};

export type BuildingCreateType = {
  description?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  dimension?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['UUID']>;
  locationId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  siteId: Scalars['UUID'];
};

export type BuildingFilterInput = {
  and?: InputMaybe<Array<BuildingFilterInput>>;
  buildingId?: InputMaybe<UuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  dimension?: InputMaybe<StringOperationFilterInput>;
  image?: InputMaybe<ImageFilterInput>;
  imageId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  location?: InputMaybe<LocationFilterInput>;
  locationId?: InputMaybe<UuidOperationFilterInput>;
  mappingDeviceBuildings?: InputMaybe<ListFilterInputTypeOfMappingDeviceBuildingFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<BuildingFilterInput>>;
  site?: InputMaybe<SiteFilterInput>;
  siteId?: InputMaybe<UuidOperationFilterInput>;
  storeys?: InputMaybe<ListFilterInputTypeOfStoreyFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  weather?: InputMaybe<WeatherFilterInput>;
};

/** A segment of a collection. */
export type BuildingFilterTableCollectionSegment = {
  __typename?: 'BuildingFilterTableCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<PlacementFilterRow>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type BuildingSetNullInputType = {
  description?: InputMaybe<Scalars['Boolean']>;
  dimension?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<Scalars['Boolean']>;
  imageId?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  site?: InputMaybe<Scalars['Boolean']>;
  storeys?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type BuildingSortInput = {
  buildingId?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  dimension?: InputMaybe<SortEnumType>;
  image?: InputMaybe<ImageSortInput>;
  imageId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  location?: InputMaybe<LocationSortInput>;
  locationId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  site?: InputMaybe<SiteSortInput>;
  siteId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  weather?: InputMaybe<WeatherSortInput>;
};

export type BuildingUpdateType = {
  description?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  dimension?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  imageId?: InputMaybe<Scalars['UUID']>;
  locationId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
  siteId?: InputMaybe<Scalars['UUID']>;
};

/** A segment of a collection. */
export type BuildingsCollectionSegment = {
  __typename?: 'BuildingsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Building>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** A segment of a collection. */
export type CapabilitiesCollectionSegment = {
  __typename?: 'CapabilitiesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Capability>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type Capability = {
  __typename?: 'Capability';
  dataType: DataTypes;
  description?: Maybe<Scalars['String']>;
  deviceModelCapabilities: Array<DeviceModelCapability>;
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tenantId?: Maybe<Scalars['UUID']>;
};


export type CapabilityDeviceModelCapabilitiesArgs = {
  order?: InputMaybe<Array<DeviceModelCapabilitySortInput>>;
  where?: InputMaybe<DeviceModelCapabilityFilterInput>;
};

export type CapabilityCreateType = {
  dataType: DataTypes;
  description?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
};

export type CapabilityFilterInput = {
  and?: InputMaybe<Array<CapabilityFilterInput>>;
  capabilityId?: InputMaybe<UuidOperationFilterInput>;
  dataType?: InputMaybe<DataTypesOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  deviceModelCapabilities?: InputMaybe<ListFilterInputTypeOfDeviceModelCapabilityFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CapabilityFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type CapabilitySetNullInputType = {
  dataType?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  deviceModelCapabilities?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type CapabilitySortInput = {
  capabilityId?: InputMaybe<SortEnumType>;
  dataType?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type CapabilityUpdateType = {
  dataType?: InputMaybe<DataTypes>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
};

export type CategorizedInstallationParts = {
  __typename?: 'CategorizedInstallationParts';
  category: CategoryType;
  installationParts: Array<BuildingInformationSchema_InstallationPart>;
};

export type CategorizedInstallationPartsScores = {
  __typename?: 'CategorizedInstallationPartsScores';
  category: CategoryType;
  installationPartScores: Array<InstallationPartScores>;
};

export type CategoryScoresInput = {
  category: CategoryType;
  scoreTypeAggregations?: InputMaybe<Array<ScoreTypeAggregationInput>>;
  scoreTypes?: InputMaybe<Array<ScoreType>>;
};

export enum CategoryType {
  Actuators = 'ACTUATORS',
  Components = 'COMPONENTS',
  ControlLoop = 'CONTROL_LOOP',
  Sensors = 'SENSORS'
}

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
};

export enum ConnectionStates {
  Connected = 'CONNECTED',
  Disconnected = 'DISCONNECTED',
  NeverConnected = 'NEVER_CONNECTED',
  Unknown = 'UNKNOWN'
}

export type ConnectionStatesOperationFilterInput = {
  eq?: InputMaybe<ConnectionStates>;
  in?: InputMaybe<Array<ConnectionStates>>;
  neq?: InputMaybe<ConnectionStates>;
  nin?: InputMaybe<Array<ConnectionStates>>;
};

export type Coordinates = {
  __typename?: 'Coordinates';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

/** Custom Feature Request object */
export type CustomFeatureRequestInput = {
  /** Description of the request */
  description: Scalars['String'];
  /** Reason of the request */
  reason?: InputMaybe<Scalars['String']>;
  /** Roles of users that should receive notifications */
  roles: Array<Scalars['String']>;
  /** User that should send notification */
  sender: UserBaseInput;
  /** Custom feature request title */
  title: Scalars['String'];
};

export type DataPoint = {
  __typename?: 'DataPoint';
  device: Device;
  deviceId?: Maybe<Scalars['UUID']>;
  deviceModelCapability?: Maybe<DeviceModelCapability>;
  deviceModelCapabilityId?: Maybe<Scalars['UUID']>;
  tenantId?: Maybe<Scalars['UUID']>;
  utcTimeMeasured?: Maybe<Scalars['DateTime']>;
  valueString?: Maybe<Scalars['String']>;
};

export type DataPointAggregated = {
  __typename?: 'DataPointAggregated';
  avg_value?: Maybe<Scalars['Float']>;
  count_values?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  device_id?: Maybe<Scalars['Int']>;
  device_model_capability_id?: Maybe<Scalars['Int']>;
  first_value?: Maybe<Scalars['Float']>;
  hour?: Maybe<Scalars['Int']>;
  last_value?: Maybe<Scalars['Float']>;
  max_value?: Maybe<Scalars['Float']>;
  min_value?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Int']>;
  quarter?: Maybe<Scalars['Int']>;
  week?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type DataQueryFiltersInput = {
  aggregationIntervalTypeActive?: InputMaybe<AggregationIntervals>;
  aggregationType?: InputMaybe<AggregationTypes>;
  readFromUtc?: InputMaybe<Scalars['DateTime']>;
  readUntilNow?: InputMaybe<Scalars['Boolean']>;
  readUntilUtc?: InputMaybe<Scalars['DateTime']>;
  timeSpan?: InputMaybe<TimeSpans>;
};

export enum DataTypes {
  Bool = 'BOOL',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Double = 'DOUBLE',
  Float = 'FLOAT',
  Integer = 'INTEGER',
  NotSpecified = 'NOT_SPECIFIED',
  String = 'STRING'
}

export type DataTypesOperationFilterInput = {
  eq?: InputMaybe<DataTypes>;
  in?: InputMaybe<Array<DataTypes>>;
  neq?: InputMaybe<DataTypes>;
  nin?: InputMaybe<Array<DataTypes>>;
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export enum DeleteE2ETestDataResult {
  Failed = 'FAILED',
  Successful = 'SUCCESSFUL'
}

export type DeleteSensorMeasurementsResponse = {
  __typename?: 'DeleteSensorMeasurementsResponse';
  numAffected: Scalars['Int'];
};

export enum DemoSiteType {
  Cube = 'CUBE',
  Dstrct = 'DSTRCT',
  Edge = 'EDGE',
  KpHeadquarters = 'KP_HEADQUARTERS',
  Ship = 'SHIP'
}

/** I am a generated type! */
export type Device = {
  __typename?: 'Device';
  connectionState: ConnectionStates;
  description?: Maybe<Scalars['String']>;
  deviceAttributeValues: Array<DeviceAttributeValue>;
  deviceIdentifier?: Maybe<Scalars['String']>;
  deviceModel: DeviceModel;
  deviceModelId: Scalars['UUID'];
  firstConnected?: Maybe<Scalars['DateTime']>;
  gatewayDeviceConnections: Array<GatewayDeviceConnection>;
  heartbeatState?: Maybe<HeartbeatStates>;
  id: Scalars['UUID'];
  inverseParentDevice: Array<Device>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  lastConnected?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  parentDevice?: Maybe<Device>;
  parentDeviceId?: Maybe<Scalars['UUID']>;
  serialNo?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['UUID']>;
};


/** I am a generated type! */
export type DeviceDeviceAttributeValuesArgs = {
  order?: InputMaybe<Array<DeviceAttributeValueSortInput>>;
  where?: InputMaybe<DeviceAttributeValueFilterInput>;
};


/** I am a generated type! */
export type DeviceGatewayDeviceConnectionsArgs = {
  order?: InputMaybe<Array<GatewayDeviceConnectionSortInput>>;
  where?: InputMaybe<GatewayDeviceConnectionFilterInput>;
};


/** I am a generated type! */
export type DeviceInverseParentDeviceArgs = {
  order?: InputMaybe<Array<DeviceSortInput>>;
  where?: InputMaybe<DeviceFilterInput>;
};

export type DeviceAndCapabilityInfo = {
  __typename?: 'DeviceAndCapabilityInfo';
  device: Device;
  deviceId: Scalars['UUID'];
  deviceModelCapability?: Maybe<DeviceModelCapability>;
  deviceModelCapabilityId: Scalars['UUID'];
  telemetryRecords?: Maybe<Array<Maybe<TelemetryRecord>>>;
  tenantId?: Maybe<Scalars['UUID']>;
};

export type DeviceAttributeValue = {
  __typename?: 'DeviceAttributeValue';
  attribute: Attribute;
  attributeId: Scalars['UUID'];
  device: Device;
  deviceId: Scalars['UUID'];
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  tenantId?: Maybe<Scalars['UUID']>;
  value?: Maybe<Scalars['String']>;
};

export type DeviceAttributeValueCreateType = {
  attributeId: Scalars['UUID'];
  deviceId: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  value?: InputMaybe<Scalars['String']>;
};

export type DeviceAttributeValueFilterInput = {
  and?: InputMaybe<Array<DeviceAttributeValueFilterInput>>;
  attribute?: InputMaybe<AttributeFilterInput>;
  attributeId?: InputMaybe<UuidOperationFilterInput>;
  device?: InputMaybe<DeviceFilterInput>;
  deviceAttributeValueId?: InputMaybe<UuidOperationFilterInput>;
  deviceId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<DeviceAttributeValueFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  value?: InputMaybe<StringOperationFilterInput>;
};

export type DeviceAttributeValueSetNullInputType = {
  attribute?: InputMaybe<Scalars['Boolean']>;
  device?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceAttributeValueSortInput = {
  attribute?: InputMaybe<AttributeSortInput>;
  attributeId?: InputMaybe<SortEnumType>;
  device?: InputMaybe<DeviceSortInput>;
  deviceAttributeValueId?: InputMaybe<SortEnumType>;
  deviceId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type DeviceAttributeValueUpdateType = {
  attributeId?: InputMaybe<Scalars['UUID']>;
  deviceId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  value?: InputMaybe<Scalars['String']>;
};

/** A segment of a collection. */
export type DeviceAttributeValuesCollectionSegment = {
  __typename?: 'DeviceAttributeValuesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<DeviceAttributeValue>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type DeviceCreate = {
  __typename?: 'DeviceCreate';
  description?: Maybe<Scalars['String']>;
  deviceIdentifier?: Maybe<Scalars['String']>;
  deviceModel: DeviceModel;
  deviceModelId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  parentDeviceId?: Maybe<Scalars['UUID']>;
  serialNo?: Maybe<Scalars['String']>;
};

export type DeviceCreateAttributeValueType = {
  attributeId: Scalars['UUID'];
  value?: InputMaybe<Scalars['String']>;
};

export type DeviceCreateInputType = {
  description?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  deviceIdentifier?: InputMaybe<Scalars['String']>;
  deviceModelId: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  parentDeviceId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=50 */
  serialNo?: InputMaybe<Scalars['String']>;
};

export type DeviceCreateType = {
  description?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  deviceIdentifier?: InputMaybe<Scalars['String']>;
  deviceModelId: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  parentDeviceId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=50 */
  serialNo?: InputMaybe<Scalars['String']>;
};

export type DeviceFilterInput = {
  and?: InputMaybe<Array<DeviceFilterInput>>;
  connectionState?: InputMaybe<ConnectionStatesOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  deviceAttributeValues?: InputMaybe<ListFilterInputTypeOfDeviceAttributeValueFilterInput>;
  deviceId?: InputMaybe<UuidOperationFilterInput>;
  deviceIdentifier?: InputMaybe<StringOperationFilterInput>;
  deviceModel?: InputMaybe<DeviceModelFilterInput>;
  deviceModelId?: InputMaybe<UuidOperationFilterInput>;
  firstConnected?: InputMaybe<DateTimeOperationFilterInput>;
  gatewayDeviceConnections?: InputMaybe<ListFilterInputTypeOfGatewayDeviceConnectionFilterInput>;
  heartbeatState?: InputMaybe<NullableOfHeartbeatStatesOperationFilterInput>;
  inverseParentDevice?: InputMaybe<ListFilterInputTypeOfDeviceFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastConnected?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DeviceFilterInput>>;
  parentDevice?: InputMaybe<DeviceFilterInput>;
  parentDeviceId?: InputMaybe<UuidOperationFilterInput>;
  serialNo?: InputMaybe<StringOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type DeviceMappingReturnType = {
  __typename?: 'DeviceMappingReturnType';
  deviceId: Scalars['UUID'];
  id: Scalars['UUID'];
  placementId: Scalars['UUID'];
};

export type DeviceMessage = {
  __typename?: 'DeviceMessage';
  connectionState: ConnectionStates;
  description?: Maybe<Scalars['String']>;
  deviceId: Scalars['UUID'];
  deviceIdentifier?: Maybe<Scalars['String']>;
  deviceModelId?: Maybe<Scalars['UUID']>;
  firstConnected?: Maybe<Scalars['DateTime']>;
  heartbeatState?: Maybe<HeartbeatStates>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastConnected?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  parentDeviceId?: Maybe<Scalars['UUID']>;
  serialNo?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['UUID']>;
};

export type DeviceModel = {
  __typename?: 'DeviceModel';
  dataMeasurementTimestampFieldSelector?: Maybe<Scalars['String']>;
  dataMeasurementTimestampFormat?: Maybe<Scalars['String']>;
  deviceIdentifierFieldSelector?: Maybe<Scalars['String']>;
  deviceModelAttributeSets: Array<DeviceModelAttributeSet>;
  deviceModelCapabilities: Array<DeviceModelCapability>;
  deviceType: DeviceType;
  deviceTypeId: Scalars['UUID'];
  devices: Array<Device>;
  gatewayReceivedDataTimestampFieldSelector?: Maybe<Scalars['String']>;
  gatewayReceivedDataTimestampFormat?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tenantId?: Maybe<Scalars['UUID']>;
};


export type DeviceModelDeviceModelAttributeSetsArgs = {
  order?: InputMaybe<Array<DeviceModelAttributeSetSortInput>>;
  where?: InputMaybe<DeviceModelAttributeSetFilterInput>;
};


export type DeviceModelDeviceModelCapabilitiesArgs = {
  order?: InputMaybe<Array<DeviceModelCapabilitySortInput>>;
  where?: InputMaybe<DeviceModelCapabilityFilterInput>;
};


export type DeviceModelDevicesArgs = {
  order?: InputMaybe<Array<DeviceSortInput>>;
  where?: InputMaybe<DeviceFilterInput>;
};

export type DeviceModelAttributeSet = {
  __typename?: 'DeviceModelAttributeSet';
  deviceModel: DeviceModel;
  deviceModelId: Scalars['UUID'];
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  mappingAttributeDeviceModelAttributeSets: Array<MappingAttributeDeviceModelAttributeSet>;
  name: Scalars['String'];
  tenantId?: Maybe<Scalars['UUID']>;
};


export type DeviceModelAttributeSetMappingAttributeDeviceModelAttributeSetsArgs = {
  order?: InputMaybe<Array<MappingAttributeDeviceModelAttributeSetSortInput>>;
  where?: InputMaybe<MappingAttributeDeviceModelAttributeSetFilterInput>;
};

export type DeviceModelAttributeSetCreateType = {
  deviceModelId: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
};

export type DeviceModelAttributeSetFilterInput = {
  and?: InputMaybe<Array<DeviceModelAttributeSetFilterInput>>;
  deviceModel?: InputMaybe<DeviceModelFilterInput>;
  deviceModelAttributeSetId?: InputMaybe<UuidOperationFilterInput>;
  deviceModelId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingAttributeDeviceModelAttributeSets?: InputMaybe<ListFilterInputTypeOfMappingAttributeDeviceModelAttributeSetFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DeviceModelAttributeSetFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type DeviceModelAttributeSetSetNullInputType = {
  deviceModel?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingAttributeDeviceModelAttributeSets?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceModelAttributeSetSortInput = {
  deviceModel?: InputMaybe<DeviceModelSortInput>;
  deviceModelAttributeSetId?: InputMaybe<SortEnumType>;
  deviceModelId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type DeviceModelAttributeSetUpdateType = {
  deviceModelId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
};

/** A segment of a collection. */
export type DeviceModelAttributeSetsCollectionSegment = {
  __typename?: 'DeviceModelAttributeSetsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<DeviceModelAttributeSet>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** A segment of a collection. */
export type DeviceModelCapabilitiesCollectionSegment = {
  __typename?: 'DeviceModelCapabilitiesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<DeviceModelCapability>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type DeviceModelCapability = {
  __typename?: 'DeviceModelCapability';
  capability: Capability;
  capabilityId: Scalars['UUID'];
  deviceModel: DeviceModel;
  deviceModelId: Scalars['UUID'];
  fieldSelector?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  technicalMax?: Maybe<Scalars['Float']>;
  technicalMin?: Maybe<Scalars['Float']>;
  tenantId?: Maybe<Scalars['UUID']>;
  unit: Unit;
  unitId: Scalars['UUID'];
};

export type DeviceModelCapabilityCreateType = {
  capabilityId: Scalars['UUID'];
  deviceModelId: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  fieldSelector?: InputMaybe<Scalars['String']>;
  technicalMax?: InputMaybe<Scalars['Float']>;
  technicalMin?: InputMaybe<Scalars['Float']>;
  unitId: Scalars['UUID'];
};

export type DeviceModelCapabilityFilterInput = {
  and?: InputMaybe<Array<DeviceModelCapabilityFilterInput>>;
  capability?: InputMaybe<CapabilityFilterInput>;
  capabilityId?: InputMaybe<UuidOperationFilterInput>;
  deviceModel?: InputMaybe<DeviceModelFilterInput>;
  deviceModelCapabilityId?: InputMaybe<UuidOperationFilterInput>;
  deviceModelId?: InputMaybe<UuidOperationFilterInput>;
  fieldSelector?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<DeviceModelCapabilityFilterInput>>;
  technicalMax?: InputMaybe<FloatOperationFilterInput>;
  technicalMin?: InputMaybe<FloatOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  unit?: InputMaybe<UnitFilterInput>;
  unitId?: InputMaybe<UuidOperationFilterInput>;
};

export type DeviceModelCapabilityIdsInput = {
  deviceModelCapabilityId?: InputMaybe<Scalars['UUID']>;
};

export type DeviceModelCapabilitySetNullInputType = {
  capability?: InputMaybe<Scalars['Boolean']>;
  deviceModel?: InputMaybe<Scalars['Boolean']>;
  fieldSelector?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  technicalMax?: InputMaybe<Scalars['Boolean']>;
  technicalMin?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
  unit?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceModelCapabilitySortInput = {
  capability?: InputMaybe<CapabilitySortInput>;
  capabilityId?: InputMaybe<SortEnumType>;
  deviceModel?: InputMaybe<DeviceModelSortInput>;
  deviceModelCapabilityId?: InputMaybe<SortEnumType>;
  deviceModelId?: InputMaybe<SortEnumType>;
  fieldSelector?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  technicalMax?: InputMaybe<SortEnumType>;
  technicalMin?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  unit?: InputMaybe<UnitSortInput>;
  unitId?: InputMaybe<SortEnumType>;
};

export type DeviceModelCapabilityUpdateType = {
  capabilityId?: InputMaybe<Scalars['UUID']>;
  deviceModelId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=250 */
  fieldSelector?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  technicalMax?: InputMaybe<Scalars['Float']>;
  technicalMin?: InputMaybe<Scalars['Float']>;
  unitId?: InputMaybe<Scalars['UUID']>;
};

export type DeviceModelCreateType = {
  /** MinLength=0, MaxLength=50 */
  dataMeasurementTimestampFieldSelector?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=25 */
  dataMeasurementTimestampFormat?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  deviceIdentifierFieldSelector?: InputMaybe<Scalars['String']>;
  deviceTypeId: Scalars['UUID'];
  /** MinLength=0, MaxLength=50 */
  gatewayReceivedDataTimestampFieldSelector?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=25 */
  gatewayReceivedDataTimestampFormat?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
};

export type DeviceModelFilterInput = {
  and?: InputMaybe<Array<DeviceModelFilterInput>>;
  dataMeasurementTimestampFieldSelector?: InputMaybe<StringOperationFilterInput>;
  dataMeasurementTimestampFormat?: InputMaybe<StringOperationFilterInput>;
  deviceIdentifierFieldSelector?: InputMaybe<StringOperationFilterInput>;
  deviceModelAttributeSets?: InputMaybe<ListFilterInputTypeOfDeviceModelAttributeSetFilterInput>;
  deviceModelCapabilities?: InputMaybe<ListFilterInputTypeOfDeviceModelCapabilityFilterInput>;
  deviceModelId?: InputMaybe<UuidOperationFilterInput>;
  deviceType?: InputMaybe<DeviceTypeFilterInput>;
  deviceTypeId?: InputMaybe<UuidOperationFilterInput>;
  devices?: InputMaybe<ListFilterInputTypeOfDeviceFilterInput>;
  gatewayReceivedDataTimestampFieldSelector?: InputMaybe<StringOperationFilterInput>;
  gatewayReceivedDataTimestampFormat?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DeviceModelFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type DeviceModelSetNullInputType = {
  dataMeasurementTimestampFieldSelector?: InputMaybe<Scalars['Boolean']>;
  dataMeasurementTimestampFormat?: InputMaybe<Scalars['Boolean']>;
  deviceIdentifierFieldSelector?: InputMaybe<Scalars['Boolean']>;
  deviceModelAttributeSets?: InputMaybe<Scalars['Boolean']>;
  deviceModelCapabilities?: InputMaybe<Scalars['Boolean']>;
  deviceType?: InputMaybe<Scalars['Boolean']>;
  devices?: InputMaybe<Scalars['Boolean']>;
  gatewayReceivedDataTimestampFieldSelector?: InputMaybe<Scalars['Boolean']>;
  gatewayReceivedDataTimestampFormat?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceModelSortInput = {
  dataMeasurementTimestampFieldSelector?: InputMaybe<SortEnumType>;
  dataMeasurementTimestampFormat?: InputMaybe<SortEnumType>;
  deviceIdentifierFieldSelector?: InputMaybe<SortEnumType>;
  deviceModelId?: InputMaybe<SortEnumType>;
  deviceType?: InputMaybe<DeviceTypeSortInput>;
  deviceTypeId?: InputMaybe<SortEnumType>;
  gatewayReceivedDataTimestampFieldSelector?: InputMaybe<SortEnumType>;
  gatewayReceivedDataTimestampFormat?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type DeviceModelUpdateType = {
  /** MinLength=0, MaxLength=50 */
  dataMeasurementTimestampFieldSelector?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=25 */
  dataMeasurementTimestampFormat?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  deviceIdentifierFieldSelector?: InputMaybe<Scalars['String']>;
  deviceTypeId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=50 */
  gatewayReceivedDataTimestampFieldSelector?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=25 */
  gatewayReceivedDataTimestampFormat?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
};

/** A segment of a collection. */
export type DeviceModelsCollectionSegment = {
  __typename?: 'DeviceModelsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<DeviceModel>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type DeviceSetNullInputType = {
  description?: InputMaybe<Scalars['Boolean']>;
  deviceAttributeValues?: InputMaybe<Scalars['Boolean']>;
  deviceIdentifier?: InputMaybe<Scalars['Boolean']>;
  deviceModel?: InputMaybe<Scalars['Boolean']>;
  gatewayDeviceConnections?: InputMaybe<Scalars['Boolean']>;
  inverseParentDevice?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  parentDevice?: InputMaybe<Scalars['Boolean']>;
  parentDeviceId?: InputMaybe<Scalars['Boolean']>;
  serialNo?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceSortInput = {
  connectionState?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  deviceId?: InputMaybe<SortEnumType>;
  deviceIdentifier?: InputMaybe<SortEnumType>;
  deviceModel?: InputMaybe<DeviceModelSortInput>;
  deviceModelId?: InputMaybe<SortEnumType>;
  firstConnected?: InputMaybe<SortEnumType>;
  heartbeatState?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lastConnected?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  parentDevice?: InputMaybe<DeviceSortInput>;
  parentDeviceId?: InputMaybe<SortEnumType>;
  serialNo?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type DeviceType = {
  __typename?: 'DeviceType';
  deviceModels: Array<DeviceModel>;
  id: Scalars['UUID'];
  isGateway?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tenantId?: Maybe<Scalars['UUID']>;
};


export type DeviceTypeDeviceModelsArgs = {
  order?: InputMaybe<Array<DeviceModelSortInput>>;
  where?: InputMaybe<DeviceModelFilterInput>;
};

export type DeviceTypeCreateType = {
  isGateway?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
};

export type DeviceTypeFilterInput = {
  and?: InputMaybe<Array<DeviceTypeFilterInput>>;
  deviceModels?: InputMaybe<ListFilterInputTypeOfDeviceModelFilterInput>;
  deviceTypeId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isGateway?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DeviceTypeFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type DeviceTypeSetNullInputType = {
  deviceModels?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isGateway?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceTypeSortInput = {
  deviceTypeId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  isGateway?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type DeviceTypeUpdateType = {
  id: Scalars['UUID'];
  isGateway?: InputMaybe<Scalars['Boolean']>;
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
};

/** A segment of a collection. */
export type DeviceTypesCollectionSegment = {
  __typename?: 'DeviceTypesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<DeviceType>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type DeviceUpdateType = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  serialNo?: InputMaybe<Scalars['String']>;
};

export type DeviceWithAttributeValues = {
  __typename?: 'DeviceWithAttributeValues';
  attributeValues?: Maybe<Array<Maybe<AttributeWithValue>>>;
  connectionState: ConnectionStates;
  description?: Maybe<Scalars['String']>;
  deviceAttributeValues?: Maybe<Array<Maybe<DeviceAttributeValue>>>;
  deviceId: Scalars['UUID'];
  deviceIdentifier?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<DeviceModel>;
  deviceModelId: Scalars['UUID'];
  firstConnected?: Maybe<Scalars['DateTime']>;
  gatewayDeviceConnections?: Maybe<Array<Maybe<GatewayDeviceConnection>>>;
  heartbeatState?: Maybe<HeartbeatStates>;
  inverseParentDevice?: Maybe<Array<Maybe<Device>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastConnected?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  parentDevice?: Maybe<Device>;
  parentDeviceId?: Maybe<Scalars['UUID']>;
  serialNo?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['UUID']>;
};

export type DevicesByBuilding = {
  __typename?: 'DevicesByBuilding';
  building: Building;
  buildingId?: Maybe<Scalars['UUID']>;
  device: Device;
  deviceAttributeValues?: Maybe<Array<ValueEntity>>;
  deviceDescription?: Maybe<Scalars['String']>;
  deviceDeviceIdentifier?: Maybe<Scalars['String']>;
  deviceGatewayName?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['UUID']>;
  deviceModelName?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  deviceSerialNumber?: Maybe<Scalars['String']>;
  deviceTypeId?: Maybe<Scalars['UUID']>;
  deviceTypeName?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['UUID']>;
  storeyId?: Maybe<Scalars['UUID']>;
  zoneId?: Maybe<Scalars['UUID']>;
};

export type DevicesByBuildingFilterInput = {
  and?: InputMaybe<Array<DevicesByBuildingFilterInput>>;
  buildingId?: InputMaybe<UuidOperationFilterInput>;
  deviceAttributeValues?: InputMaybe<ListFilterInputTypeOfValueEntityFilterInput>;
  deviceDescription?: InputMaybe<StringOperationFilterInput>;
  deviceDeviceIdentifier?: InputMaybe<StringOperationFilterInput>;
  deviceGatewayName?: InputMaybe<StringOperationFilterInput>;
  deviceId?: InputMaybe<UuidOperationFilterInput>;
  deviceModelName?: InputMaybe<StringOperationFilterInput>;
  deviceName?: InputMaybe<StringOperationFilterInput>;
  deviceSerialNumber?: InputMaybe<StringOperationFilterInput>;
  deviceTypeId?: InputMaybe<UuidOperationFilterInput>;
  deviceTypeName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DevicesByBuildingFilterInput>>;
  siteId?: InputMaybe<UuidOperationFilterInput>;
  storeyId?: InputMaybe<UuidOperationFilterInput>;
  zoneId?: InputMaybe<UuidOperationFilterInput>;
};

export type DevicesByBuildingSortInput = {
  buildingId?: InputMaybe<SortEnumType>;
  deviceDescription?: InputMaybe<SortEnumType>;
  deviceDeviceIdentifier?: InputMaybe<SortEnumType>;
  deviceGatewayName?: InputMaybe<SortEnumType>;
  deviceId?: InputMaybe<SortEnumType>;
  deviceModelName?: InputMaybe<SortEnumType>;
  deviceName?: InputMaybe<SortEnumType>;
  deviceSerialNumber?: InputMaybe<SortEnumType>;
  deviceTypeId?: InputMaybe<SortEnumType>;
  deviceTypeName?: InputMaybe<SortEnumType>;
  siteId?: InputMaybe<SortEnumType>;
  storeyId?: InputMaybe<SortEnumType>;
  zoneId?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type DevicesByBuildingsCollectionSegment = {
  __typename?: 'DevicesByBuildingsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<DevicesByBuilding>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** A segment of a collection. */
export type DevicesCollectionSegment = {
  __typename?: 'DevicesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Device>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** User Request object */
export type EmailContactRequestInput = {
  /** Message */
  message: Scalars['String'];
  /** User that should receive notification */
  recipient: UserBaseInput;
  /** User that should send notification */
  sender: UserBaseInput;
  /** Subject of the message */
  subject: Scalars['String'];
};

export type ExternalIdScoreTypesInput = {
  externalId: Scalars['String'];
  scoreTypes: Array<ScoreType>;
};

export type FloatOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  ngt?: InputMaybe<Scalars['Float']>;
  ngte?: InputMaybe<Scalars['Float']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  nlt?: InputMaybe<Scalars['Float']>;
  nlte?: InputMaybe<Scalars['Float']>;
};

export type Forecast = {
  __typename?: 'Forecast';
  date: Scalars['DateTime'];
  iceProbability: Scalars['Int'];
  iconCode: Scalars['Int'];
  iconPhrase: Scalars['String'];
  precipitationProbability: Scalars['Int'];
  rainProbability: Scalars['Int'];
  realFeelTemperature: Temperature;
  relativeHumidity: Scalars['Int'];
  snowProbability: Scalars['Int'];
  temperature: Temperature;
};

export type ForecastResult = {
  __typename?: 'ForecastResult';
  forecasts: Array<Forecast>;
};

export type GatewayDeviceConnection = {
  __typename?: 'GatewayDeviceConnection';
  device: Device;
  deviceId: Scalars['UUID'];
  host: Scalars['String'];
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  port: Scalars['Int'];
  primaryAuthenticationKey: Scalars['String'];
  primaryConnectionString?: Maybe<Scalars['String']>;
  secondaryAuthenticationKey: Scalars['String'];
  secondaryConnectionString?: Maybe<Scalars['String']>;
  sharedAccessSignatureExpiry?: Maybe<Scalars['DateTime']>;
  tenantId?: Maybe<Scalars['UUID']>;
  user: Scalars['String'];
};

export type GatewayDeviceConnectionCreateType = {
  deviceId: Scalars['UUID'];
  /** MinLength=0, MaxLength=100 */
  name: Scalars['String'];
  /** MinLength=0, MaxLength=250 */
  primaryAuthenticationKey: Scalars['String'];
  /** MinLength=0, MaxLength=250 */
  primaryConnectionString?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  secondaryAuthenticationKey: Scalars['String'];
  /** MinLength=0, MaxLength=250 */
  secondaryConnectionString?: InputMaybe<Scalars['String']>;
};

export type GatewayDeviceConnectionFilterInput = {
  and?: InputMaybe<Array<GatewayDeviceConnectionFilterInput>>;
  device?: InputMaybe<DeviceFilterInput>;
  deviceId?: InputMaybe<UuidOperationFilterInput>;
  gatewayDeviceConnectionId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<GatewayDeviceConnectionFilterInput>>;
  primaryAuthenticationKey?: InputMaybe<StringOperationFilterInput>;
  primaryConnectionString?: InputMaybe<StringOperationFilterInput>;
  secondaryAuthenticationKey?: InputMaybe<StringOperationFilterInput>;
  secondaryConnectionString?: InputMaybe<StringOperationFilterInput>;
  sharedAccessSignatureExpiry?: InputMaybe<DateTimeOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type GatewayDeviceConnectionSetNullInputType = {
  device?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  primaryConnectionString?: InputMaybe<Scalars['Boolean']>;
  secondaryConnectionString?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type GatewayDeviceConnectionSortInput = {
  device?: InputMaybe<DeviceSortInput>;
  deviceId?: InputMaybe<SortEnumType>;
  gatewayDeviceConnectionId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  primaryAuthenticationKey?: InputMaybe<SortEnumType>;
  primaryConnectionString?: InputMaybe<SortEnumType>;
  secondaryAuthenticationKey?: InputMaybe<SortEnumType>;
  secondaryConnectionString?: InputMaybe<SortEnumType>;
  sharedAccessSignatureExpiry?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type GatewayDeviceConnectionUpdateType = {
  deviceId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=100 */
  name?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  primaryAuthenticationKey?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  primaryConnectionString?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  secondaryAuthenticationKey?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  secondaryConnectionString?: InputMaybe<Scalars['String']>;
};

/** A segment of a collection. */
export type GatewayDeviceConnectionsCollectionSegment = {
  __typename?: 'GatewayDeviceConnectionsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<GatewayDeviceConnection>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type GatewayProperties = IGateway_Gateway_Properties & {
  __typename?: 'GatewayProperties';
  firmwareVersion?: Maybe<Scalars['String']>;
};

export type Gateway_Gateway_Gateway = {
  __typename?: 'Gateway_Gateway_Gateway';
  id: Scalars['String'];
  properties?: Maybe<IGateway_Gateway_Properties>;
  status?: Maybe<Scalars['String']>;
};

export type Gateway_Gateway_Properties_GatewayProperties = IGateway_Gateway_Properties & {
  __typename?: 'Gateway_Gateway_Properties_GatewayProperties';
  firmwareVersion?: Maybe<Scalars['String']>;
};

export type GroupedSystemsScore = {
  __typename?: 'GroupedSystemsScore';
  score?: Maybe<Scalars['Float']>;
  systemType: SystemType;
};

export enum HeartbeatStates {
  Offline = 'OFFLINE',
  Online = 'ONLINE',
  Unknown = 'UNKNOWN'
}

export type IGateway_Gateway_Properties = {
  firmwareVersion?: Maybe<Scalars['String']>;
};

/** Idea Request object */
export type IdeaRequestInput = {
  /** Description of the idea */
  description: Scalars['String'];
  /** Roles of users that should receive notifications */
  roles: Array<Scalars['String']>;
  /** User that should send notification */
  sender: UserBaseInput;
  /** Subject of the idea */
  subject: Scalars['String'];
};

/** I am a generated type! */
export type Image = {
  __typename?: 'Image';
  buildings: Array<Building>;
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  mappingDeviceImages: Array<MappingDeviceImage>;
  name: Scalars['String'];
  sites: Array<Site>;
  storeys: Array<Storey>;
  tenantId?: Maybe<Scalars['UUID']>;
  url?: Maybe<Scalars['String']>;
  zones: Array<Zone>;
};


/** I am a generated type! */
export type ImageBuildingsArgs = {
  order?: InputMaybe<Array<BuildingSortInput>>;
  where?: InputMaybe<BuildingFilterInput>;
};


/** I am a generated type! */
export type ImageMappingDeviceImagesArgs = {
  order?: InputMaybe<Array<MappingDeviceImageSortInput>>;
  where?: InputMaybe<MappingDeviceImageFilterInput>;
};


/** I am a generated type! */
export type ImageSitesArgs = {
  order?: InputMaybe<Array<SiteSortInput>>;
  where?: InputMaybe<SiteFilterInput>;
};


/** I am a generated type! */
export type ImageStoreysArgs = {
  order?: InputMaybe<Array<StoreySortInput>>;
  where?: InputMaybe<StoreyFilterInput>;
};


/** I am a generated type! */
export type ImageZonesArgs = {
  order?: InputMaybe<Array<ZoneSortInput>>;
  where?: InputMaybe<ZoneFilterInput>;
};

export type ImageCreateType = {
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  /** MinLength=0, MaxLength=500 */
  url?: InputMaybe<Scalars['String']>;
};

export type ImageFilterInput = {
  and?: InputMaybe<Array<ImageFilterInput>>;
  buildings?: InputMaybe<ListFilterInputTypeOfBuildingFilterInput>;
  imageId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceImages?: InputMaybe<ListFilterInputTypeOfMappingDeviceImageFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ImageFilterInput>>;
  sites?: InputMaybe<ListFilterInputTypeOfSiteFilterInput>;
  storeys?: InputMaybe<ListFilterInputTypeOfStoreyFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  url?: InputMaybe<StringOperationFilterInput>;
  zones?: InputMaybe<ListFilterInputTypeOfZoneFilterInput>;
};

export type ImageSetNullInputType = {
  buildings?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingDeviceImages?: InputMaybe<Scalars['Boolean']>;
  sites?: InputMaybe<Scalars['Boolean']>;
  storeys?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
  url?: InputMaybe<Scalars['Boolean']>;
  zones?: InputMaybe<Scalars['Boolean']>;
};

export type ImageSortInput = {
  imageId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  url?: InputMaybe<SortEnumType>;
};

export type ImageUpdateType = {
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=500 */
  url?: InputMaybe<Scalars['String']>;
};

/** A segment of a collection. */
export type ImagesCollectionSegment = {
  __typename?: 'ImagesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Image>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type InstallationPart = {
  __typename?: 'InstallationPart';
  id: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type InstallationPartScores = {
  __typename?: 'InstallationPartScores';
  installationPart: InstallationPart;
  scores: Array<Score>;
  value?: Maybe<Scalars['Float']>;
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export type ListFilterInputTypeOfAttributeDefinitionFilterInput = {
  all?: InputMaybe<AttributeDefinitionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<AttributeDefinitionFilterInput>;
  some?: InputMaybe<AttributeDefinitionFilterInput>;
};

export type ListFilterInputTypeOfBuildingFilterInput = {
  all?: InputMaybe<BuildingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<BuildingFilterInput>;
  some?: InputMaybe<BuildingFilterInput>;
};

export type ListFilterInputTypeOfDeviceAttributeValueFilterInput = {
  all?: InputMaybe<DeviceAttributeValueFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeviceAttributeValueFilterInput>;
  some?: InputMaybe<DeviceAttributeValueFilterInput>;
};

export type ListFilterInputTypeOfDeviceFilterInput = {
  all?: InputMaybe<DeviceFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeviceFilterInput>;
  some?: InputMaybe<DeviceFilterInput>;
};

export type ListFilterInputTypeOfDeviceModelAttributeSetFilterInput = {
  all?: InputMaybe<DeviceModelAttributeSetFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeviceModelAttributeSetFilterInput>;
  some?: InputMaybe<DeviceModelAttributeSetFilterInput>;
};

export type ListFilterInputTypeOfDeviceModelCapabilityFilterInput = {
  all?: InputMaybe<DeviceModelCapabilityFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeviceModelCapabilityFilterInput>;
  some?: InputMaybe<DeviceModelCapabilityFilterInput>;
};

export type ListFilterInputTypeOfDeviceModelFilterInput = {
  all?: InputMaybe<DeviceModelFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeviceModelFilterInput>;
  some?: InputMaybe<DeviceModelFilterInput>;
};

export type ListFilterInputTypeOfGatewayDeviceConnectionFilterInput = {
  all?: InputMaybe<GatewayDeviceConnectionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<GatewayDeviceConnectionFilterInput>;
  some?: InputMaybe<GatewayDeviceConnectionFilterInput>;
};

export type ListFilterInputTypeOfMappingAttributeDeviceModelAttributeSetFilterInput = {
  all?: InputMaybe<MappingAttributeDeviceModelAttributeSetFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingAttributeDeviceModelAttributeSetFilterInput>;
  some?: InputMaybe<MappingAttributeDeviceModelAttributeSetFilterInput>;
};

export type ListFilterInputTypeOfMappingDeviceBuildingFilterInput = {
  all?: InputMaybe<MappingDeviceBuildingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingDeviceBuildingFilterInput>;
  some?: InputMaybe<MappingDeviceBuildingFilterInput>;
};

export type ListFilterInputTypeOfMappingDeviceImageFilterInput = {
  all?: InputMaybe<MappingDeviceImageFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingDeviceImageFilterInput>;
  some?: InputMaybe<MappingDeviceImageFilterInput>;
};

export type ListFilterInputTypeOfMappingDeviceZoneFilterInput = {
  all?: InputMaybe<MappingDeviceZoneFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<MappingDeviceZoneFilterInput>;
  some?: InputMaybe<MappingDeviceZoneFilterInput>;
};

export type ListFilterInputTypeOfSiteFilterInput = {
  all?: InputMaybe<SiteFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<SiteFilterInput>;
  some?: InputMaybe<SiteFilterInput>;
};

export type ListFilterInputTypeOfStoreyFilterInput = {
  all?: InputMaybe<StoreyFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<StoreyFilterInput>;
  some?: InputMaybe<StoreyFilterInput>;
};

export type ListFilterInputTypeOfValueEntityFilterInput = {
  all?: InputMaybe<ValueEntityFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ValueEntityFilterInput>;
  some?: InputMaybe<ValueEntityFilterInput>;
};

export type ListFilterInputTypeOfZoneFilterInput = {
  all?: InputMaybe<ZoneFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ZoneFilterInput>;
  some?: InputMaybe<ZoneFilterInput>;
};

export type ListStringOperationFilterInput = {
  all?: InputMaybe<StringOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<StringOperationFilterInput>;
  some?: InputMaybe<StringOperationFilterInput>;
};

/** I am a generated type! */
export type Location = {
  __typename?: 'Location';
  buildings: Array<Building>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  no?: Maybe<Scalars['String']>;
  sites: Array<Site>;
  street?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['UUID']>;
  zip?: Maybe<Scalars['String']>;
};


/** I am a generated type! */
export type LocationBuildingsArgs = {
  order?: InputMaybe<Array<BuildingSortInput>>;
  where?: InputMaybe<BuildingFilterInput>;
};


/** I am a generated type! */
export type LocationSitesArgs = {
  order?: InputMaybe<Array<SiteSortInput>>;
  where?: InputMaybe<SiteFilterInput>;
};

export type LocationCreateType = {
  /** MinLength=0, MaxLength=250 */
  city?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  country?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  county?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  no?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  street?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  zip?: InputMaybe<Scalars['String']>;
};

export type LocationFilterInput = {
  and?: InputMaybe<Array<LocationFilterInput>>;
  buildings?: InputMaybe<ListFilterInputTypeOfBuildingFilterInput>;
  city?: InputMaybe<StringOperationFilterInput>;
  country?: InputMaybe<StringOperationFilterInput>;
  county?: InputMaybe<StringOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lat?: InputMaybe<FloatOperationFilterInput>;
  locationId?: InputMaybe<UuidOperationFilterInput>;
  lon?: InputMaybe<FloatOperationFilterInput>;
  no?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<LocationFilterInput>>;
  sites?: InputMaybe<ListFilterInputTypeOfSiteFilterInput>;
  street?: InputMaybe<StringOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  zip?: InputMaybe<StringOperationFilterInput>;
};

export type LocationSetNullInputType = {
  buildings?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['Boolean']>;
  country?: InputMaybe<Scalars['Boolean']>;
  county?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  no?: InputMaybe<Scalars['Boolean']>;
  sites?: InputMaybe<Scalars['Boolean']>;
  street?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
  zip?: InputMaybe<Scalars['Boolean']>;
};

export type LocationSortInput = {
  city?: InputMaybe<SortEnumType>;
  country?: InputMaybe<SortEnumType>;
  county?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lat?: InputMaybe<SortEnumType>;
  locationId?: InputMaybe<SortEnumType>;
  lon?: InputMaybe<SortEnumType>;
  no?: InputMaybe<SortEnumType>;
  street?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  zip?: InputMaybe<SortEnumType>;
};

export type LocationUpdateType = {
  /** MinLength=0, MaxLength=250 */
  city?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  country?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  county?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=50 */
  no?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  street?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=50 */
  zip?: InputMaybe<Scalars['String']>;
};

/** A segment of a collection. */
export type LocationsCollectionSegment = {
  __typename?: 'LocationsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Location>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type MappingAttributeDeviceModelAttributeSet = {
  __typename?: 'MappingAttributeDeviceModelAttributeSet';
  attribute: Attribute;
  attributeId: Scalars['UUID'];
  deviceModelAttributeSet: DeviceModelAttributeSet;
  deviceModelAttributeSetId: Scalars['UUID'];
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  tenantId?: Maybe<Scalars['UUID']>;
};

export type MappingAttributeDeviceModelAttributeSetCreateType = {
  attributeId: Scalars['UUID'];
  deviceModelAttributeSetId: Scalars['UUID'];
};

export type MappingAttributeDeviceModelAttributeSetFilterInput = {
  and?: InputMaybe<Array<MappingAttributeDeviceModelAttributeSetFilterInput>>;
  attribute?: InputMaybe<AttributeFilterInput>;
  attributeId?: InputMaybe<UuidOperationFilterInput>;
  deviceModelAttributeSet?: InputMaybe<DeviceModelAttributeSetFilterInput>;
  deviceModelAttributeSetId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingAttributeDeviceModelAttributeSetId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingAttributeDeviceModelAttributeSetFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type MappingAttributeDeviceModelAttributeSetSetNullInputType = {
  attribute?: InputMaybe<Scalars['Boolean']>;
  deviceModelAttributeSet?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type MappingAttributeDeviceModelAttributeSetSortInput = {
  attribute?: InputMaybe<AttributeSortInput>;
  attributeId?: InputMaybe<SortEnumType>;
  deviceModelAttributeSet?: InputMaybe<DeviceModelAttributeSetSortInput>;
  deviceModelAttributeSetId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  mappingAttributeDeviceModelAttributeSetId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type MappingAttributeDeviceModelAttributeSetUpdateType = {
  attributeId?: InputMaybe<Scalars['UUID']>;
  deviceModelAttributeSetId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
};

/** A segment of a collection. */
export type MappingAttributeDeviceModelAttributeSetsCollectionSegment = {
  __typename?: 'MappingAttributeDeviceModelAttributeSetsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<MappingAttributeDeviceModelAttributeSet>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** I am a generated type! */
export type MappingDeviceBuilding = {
  __typename?: 'MappingDeviceBuilding';
  building: Building;
  buildingId: Scalars['UUID'];
  device: Device;
  deviceId: Scalars['UUID'];
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  tenantId?: Maybe<Scalars['UUID']>;
};

export type MappingDeviceBuildingCreateType = {
  buildingId: Scalars['UUID'];
  deviceId: Scalars['UUID'];
};

export type MappingDeviceBuildingFilterInput = {
  and?: InputMaybe<Array<MappingDeviceBuildingFilterInput>>;
  building?: InputMaybe<BuildingFilterInput>;
  buildingId?: InputMaybe<UuidOperationFilterInput>;
  deviceId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceBuildingId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingDeviceBuildingFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type MappingDeviceBuildingSetNullInputType = {
  building?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type MappingDeviceBuildingSortInput = {
  building?: InputMaybe<BuildingSortInput>;
  buildingId?: InputMaybe<SortEnumType>;
  deviceId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  mappingDeviceBuildingId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type MappingDeviceBuildingUpdateType = {
  buildingId?: InputMaybe<Scalars['UUID']>;
  deviceId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
};

/** A segment of a collection. */
export type MappingDeviceBuildingsCollectionSegment = {
  __typename?: 'MappingDeviceBuildingsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<MappingDeviceBuilding>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** I am a generated type! */
export type MappingDeviceImage = {
  __typename?: 'MappingDeviceImage';
  device: Device;
  deviceId: Scalars['UUID'];
  id: Scalars['UUID'];
  image: Image;
  imageId: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  tenantId?: Maybe<Scalars['UUID']>;
};

export type MappingDeviceImageCreateType = {
  deviceId: Scalars['UUID'];
  imageId: Scalars['UUID'];
};

export type MappingDeviceImageFilterInput = {
  and?: InputMaybe<Array<MappingDeviceImageFilterInput>>;
  deviceId?: InputMaybe<UuidOperationFilterInput>;
  image?: InputMaybe<ImageFilterInput>;
  imageId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceImageId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingDeviceImageFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type MappingDeviceImageSetNullInputType = {
  image?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type MappingDeviceImageSortInput = {
  deviceId?: InputMaybe<SortEnumType>;
  image?: InputMaybe<ImageSortInput>;
  imageId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  mappingDeviceImageId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type MappingDeviceImageUpdateType = {
  deviceId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  imageId?: InputMaybe<Scalars['UUID']>;
};

/** A segment of a collection. */
export type MappingDeviceImagesCollectionSegment = {
  __typename?: 'MappingDeviceImagesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<MappingDeviceImage>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

/** I am a generated type! */
export type MappingDeviceZone = {
  __typename?: 'MappingDeviceZone';
  device: Device;
  deviceId: Scalars['UUID'];
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  tenantId?: Maybe<Scalars['UUID']>;
  zone: Zone;
  zoneId: Scalars['UUID'];
};

export type MappingDeviceZoneCreateType = {
  deviceId: Scalars['UUID'];
  zoneId: Scalars['UUID'];
};

export type MappingDeviceZoneFilterInput = {
  and?: InputMaybe<Array<MappingDeviceZoneFilterInput>>;
  deviceId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceZoneId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<MappingDeviceZoneFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  zone?: InputMaybe<ZoneFilterInput>;
  zoneId?: InputMaybe<UuidOperationFilterInput>;
};

export type MappingDeviceZoneSetNullInputType = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
  zone?: InputMaybe<Scalars['Boolean']>;
};

export type MappingDeviceZoneSortInput = {
  deviceId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  mappingDeviceZoneId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  zone?: InputMaybe<ZoneSortInput>;
  zoneId?: InputMaybe<SortEnumType>;
};

export type MappingDeviceZoneUpdateType = {
  deviceId?: InputMaybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  zoneId?: InputMaybe<Scalars['UUID']>;
};

/** A segment of a collection. */
export type MappingDeviceZonesCollectionSegment = {
  __typename?: 'MappingDeviceZonesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<MappingDeviceZone>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  bulkProvisionIoTHubDevices: Scalars['String'];
  cleanupGatewayDevicesFromIoTHub: Scalars['String'];
  createAttribute?: Maybe<Attribute>;
  createAttributes: Array<Maybe<Attribute>>;
  createAuditLog?: Maybe<AuditLog>;
  createAuditLogs: Array<Maybe<AuditLog>>;
  createBuilding?: Maybe<Building>;
  createBuildings: Array<Maybe<Building>>;
  createCapabilities: Array<Maybe<Capability>>;
  createCapability?: Maybe<Capability>;
  createDeviceModel?: Maybe<DeviceModel>;
  createDeviceModelAttributeSet?: Maybe<DeviceModelAttributeSet>;
  createDeviceModelAttributeSets: Array<Maybe<DeviceModelAttributeSet>>;
  createDeviceModelCapabilities: Array<Maybe<DeviceModelCapability>>;
  createDeviceModelCapability?: Maybe<DeviceModelCapability>;
  createDeviceModels: Array<Maybe<DeviceModel>>;
  createDeviceType?: Maybe<DeviceType>;
  createDeviceTypes: Array<Maybe<DeviceType>>;
  createDeviceWithPlacement: DeviceCreate;
  createGatewayDeviceConnection?: Maybe<GatewayDeviceConnection>;
  createGatewayDeviceConnections: Array<Maybe<GatewayDeviceConnection>>;
  createImage?: Maybe<Image>;
  createImages: Array<Maybe<Image>>;
  createLocation?: Maybe<Location>;
  createLocations: Array<Maybe<Location>>;
  createMappingAttributeDeviceModelAttributeSet?: Maybe<MappingAttributeDeviceModelAttributeSet>;
  createMappingAttributeDeviceModelAttributeSets: Array<Maybe<MappingAttributeDeviceModelAttributeSet>>;
  createMappingDeviceBuilding?: Maybe<MappingDeviceBuilding>;
  createMappingDeviceBuildings: Array<Maybe<MappingDeviceBuilding>>;
  createMappingDeviceImage?: Maybe<MappingDeviceImage>;
  createMappingDeviceImages: Array<Maybe<MappingDeviceImage>>;
  createMappingDeviceZone?: Maybe<MappingDeviceZone>;
  createMappingDeviceZones: Array<Maybe<MappingDeviceZone>>;
  createRule?: Maybe<Rule>;
  createRuleWithChecks: RuleWithChecks;
  createRules: Array<Maybe<Rule>>;
  createRulesWithChecks: Array<RuleWithChecks>;
  /** Create a new schedule. */
  createSchedule: Schedule;
  createSite?: Maybe<Site>;
  createSites: Array<Maybe<Site>>;
  createStorey?: Maybe<Storey>;
  createStoreys: Array<Maybe<Storey>>;
  createUnit?: Maybe<Unit>;
  createUnits: Array<Maybe<Unit>>;
  createZone?: Maybe<Zone>;
  createZones: Array<Maybe<Zone>>;
  deleteAttribute?: Maybe<Attribute>;
  deleteAttributes: Array<Maybe<Attribute>>;
  deleteBuilding?: Maybe<Building>;
  deleteBuildings: Array<Maybe<Building>>;
  deleteCapabilities: Array<Maybe<Capability>>;
  deleteCapability?: Maybe<Capability>;
  deleteDevice?: Maybe<Device>;
  /** Delete list of sensor measurements by device and device capability */
  deleteDeviceCapabilitySensorMeasurements?: Maybe<DeleteSensorMeasurementsResponse>;
  deleteDeviceModel?: Maybe<DeviceModel>;
  deleteDeviceModelAttributeSet?: Maybe<DeviceModelAttributeSet>;
  deleteDeviceModelAttributeSets: Array<Maybe<DeviceModelAttributeSet>>;
  deleteDeviceModelCapabilities: Array<Maybe<DeviceModelCapability>>;
  deleteDeviceModelCapability?: Maybe<DeviceModelCapability>;
  deleteDeviceModels: Array<Maybe<DeviceModel>>;
  deleteDeviceType?: Maybe<DeviceType>;
  deleteDeviceTypes: Array<Maybe<DeviceType>>;
  deleteDevices: Array<Maybe<Device>>;
  deleteDevicesByGatewayId: DeleteE2ETestDataResult;
  deleteGatewayDeviceConnection?: Maybe<GatewayDeviceConnection>;
  deleteGatewayDeviceConnections: Array<Maybe<GatewayDeviceConnection>>;
  deleteImage?: Maybe<Image>;
  deleteImages: Array<Maybe<Image>>;
  deleteLocation?: Maybe<Location>;
  deleteLocations: Array<Maybe<Location>>;
  deleteMappingAttributeDeviceModelAttributeSet?: Maybe<MappingAttributeDeviceModelAttributeSet>;
  deleteMappingAttributeDeviceModelAttributeSets: Array<Maybe<MappingAttributeDeviceModelAttributeSet>>;
  deleteMappingDeviceBuilding?: Maybe<MappingDeviceBuilding>;
  deleteMappingDeviceBuildings: Array<Maybe<MappingDeviceBuilding>>;
  deleteMappingDeviceImage?: Maybe<MappingDeviceImage>;
  deleteMappingDeviceImages: Array<Maybe<MappingDeviceImage>>;
  deleteMappingDeviceZone?: Maybe<MappingDeviceZone>;
  deleteMappingDeviceZones: Array<Maybe<MappingDeviceZone>>;
  deleteRule?: Maybe<Rule>;
  deleteRules: Array<Maybe<Rule>>;
  /** Delete a schedule by its ID. */
  deleteSchedule: Scalars['Boolean'];
  deleteSite?: Maybe<Site>;
  deleteSites: Array<Maybe<Site>>;
  deleteStorey?: Maybe<Storey>;
  deleteStoreys: Array<Maybe<Storey>>;
  deleteUnit?: Maybe<Unit>;
  deleteUnits: Array<Maybe<Unit>>;
  deleteZone?: Maybe<Zone>;
  deleteZones: Array<Maybe<Zone>>;
  exportIoTDevicesToBlobStorage: Scalars['String'];
  rebuildDeviceMappingsInStorageTable: Scalars['String'];
  redoCreateGatewayDeviceNotification?: Maybe<Scalars['String']>;
  regenerateGatewayDeviceConnectionKey?: Maybe<GatewayDeviceConnection>;
  regenerateGatewayDeviceConnectionsKeys: Array<Maybe<GatewayDeviceConnection>>;
  regenerateGatewayDeviceSharedAccessSignature: SharedAccessSignatureData;
  regenerateTenantGatewayDeviceConnectionsKeys: Array<GatewayDeviceConnection>;
  removeDeletedDeviceMappingsInStorageTable: Scalars['String'];
  /** Send new email contact request to selected user */
  sendContactRequestByEmail: Scalars['Boolean'];
  /** Send new custom request to users in selected roles */
  sendCustomFeatureRequestByRoles: Scalars['Boolean'];
  /** Send new idea request to users in selected roles */
  sendIdeaRequestByRoles: Scalars['Boolean'];
  /** Send report by email to selected users */
  sendReportByEmail: Scalars['Boolean'];
  setDeviceAttributeValue?: Maybe<DeviceAttributeValue>;
  setDeviceAttributeValues: Array<Maybe<DeviceAttributeValue>>;
  testSubscriptionDeviceMessage?: Maybe<DeviceMessage>;
  unsetDeviceAttributeValue?: Maybe<DeviceAttributeValue>;
  unsetDeviceAttributeValues: Array<Maybe<DeviceAttributeValue>>;
  updateAttribute: Attribute;
  updateAttributes: Array<Attribute>;
  updateBuilding: Building;
  updateBuildings: Array<Building>;
  updateCapabilities: Array<Capability>;
  updateCapability: Capability;
  updateDevice: Device;
  updateDeviceModel: DeviceModel;
  updateDeviceModelAttributeSet: DeviceModelAttributeSet;
  updateDeviceModelAttributeSets: Array<DeviceModelAttributeSet>;
  updateDeviceModelCapabilities: Array<DeviceModelCapability>;
  updateDeviceModelCapability: DeviceModelCapability;
  updateDeviceModels: Array<DeviceModel>;
  updateDeviceType: DeviceType;
  updateDeviceTypes: Array<DeviceType>;
  updateDevices: Array<Device>;
  updateGatewayDeviceConnection: GatewayDeviceConnection;
  updateGatewayDeviceConnections: Array<GatewayDeviceConnection>;
  updateImage: Image;
  updateImages: Array<Image>;
  updateLocation: Location;
  updateLocations: Array<Location>;
  updateMappingAttributeDeviceModelAttributeSet: MappingAttributeDeviceModelAttributeSet;
  updateMappingAttributeDeviceModelAttributeSets: Array<MappingAttributeDeviceModelAttributeSet>;
  updateMappingDeviceBuilding: MappingDeviceBuilding;
  updateMappingDeviceBuildingToBuilding: MappingDeviceBuilding;
  updateMappingDeviceBuildingToZone: MappingDeviceZone;
  updateMappingDeviceBuildings: Array<MappingDeviceBuilding>;
  updateMappingDeviceImage: MappingDeviceImage;
  updateMappingDeviceImages: Array<MappingDeviceImage>;
  updateMappingDeviceZone: MappingDeviceZone;
  updateMappingDeviceZoneToBuilding: MappingDeviceBuilding;
  updateMappingDeviceZoneToZone: MappingDeviceZone;
  updateMappingDeviceZones: Array<MappingDeviceZone>;
  updatePlacementOfDevice: DeviceMappingReturnType;
  updateRule: Rule;
  updateRuleWithChecks: RuleWithChecks;
  updateRules: Array<Rule>;
  updateRulesWithChecks: Array<RuleWithChecks>;
  /** Update an existing schedule. */
  updateSchedule: Schedule;
  updateSite: Site;
  updateSites: Array<Site>;
  updateStorey: Storey;
  updateStoreys: Array<Storey>;
  updateStressTestMappingTablesAsync: Scalars['String'];
  updateUnit: Unit;
  updateUnits: Array<Unit>;
  updateZone: Zone;
  updateZones: Array<Zone>;
};


export type MutationBulkProvisionIoTHubDevicesArgs = {
  numDevices: Scalars['Int'];
  numTenants: Scalars['Int'];
};


export type MutationCleanupGatewayDevicesFromIoTHubArgs = {
  tenantIds?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationCreateAttributeArgs = {
  attribute: AttributeCreateType;
};


export type MutationCreateAttributesArgs = {
  attributes: Array<AttributeCreateType>;
};


export type MutationCreateAuditLogArgs = {
  auditLog: AuditLogCreateType;
};


export type MutationCreateAuditLogsArgs = {
  auditLogs: Array<AuditLogCreateType>;
};


export type MutationCreateBuildingArgs = {
  building: BuildingCreateType;
};


export type MutationCreateBuildingsArgs = {
  buildings: Array<BuildingCreateType>;
};


export type MutationCreateCapabilitiesArgs = {
  capabilities: Array<CapabilityCreateType>;
};


export type MutationCreateCapabilityArgs = {
  capability: CapabilityCreateType;
};


export type MutationCreateDeviceModelArgs = {
  deviceModel: DeviceModelCreateType;
};


export type MutationCreateDeviceModelAttributeSetArgs = {
  deviceModelAttributeSet: DeviceModelAttributeSetCreateType;
};


export type MutationCreateDeviceModelAttributeSetsArgs = {
  deviceModelAttributeSets: Array<DeviceModelAttributeSetCreateType>;
};


export type MutationCreateDeviceModelCapabilitiesArgs = {
  deviceModelCapabilities: Array<DeviceModelCapabilityCreateType>;
};


export type MutationCreateDeviceModelCapabilityArgs = {
  deviceModelCapability: DeviceModelCapabilityCreateType;
};


export type MutationCreateDeviceModelsArgs = {
  deviceModels: Array<DeviceModelCreateType>;
};


export type MutationCreateDeviceTypeArgs = {
  deviceType: DeviceTypeCreateType;
};


export type MutationCreateDeviceTypesArgs = {
  deviceTypes: Array<DeviceTypeCreateType>;
};


export type MutationCreateDeviceWithPlacementArgs = {
  attributeValues: Array<DeviceCreateAttributeValueType>;
  device: DeviceCreateInputType;
  placementId: Scalars['UUID'];
  placementType: PlacementKind;
};


export type MutationCreateGatewayDeviceConnectionArgs = {
  gatewayDeviceConnection: GatewayDeviceConnectionCreateType;
};


export type MutationCreateGatewayDeviceConnectionsArgs = {
  gatewayDeviceConnections: Array<GatewayDeviceConnectionCreateType>;
};


export type MutationCreateImageArgs = {
  image: ImageCreateType;
};


export type MutationCreateImagesArgs = {
  images: Array<ImageCreateType>;
};


export type MutationCreateLocationArgs = {
  location: LocationCreateType;
};


export type MutationCreateLocationsArgs = {
  locations: Array<LocationCreateType>;
};


export type MutationCreateMappingAttributeDeviceModelAttributeSetArgs = {
  mappingAttributeDeviceModelAttributeSet: MappingAttributeDeviceModelAttributeSetCreateType;
};


export type MutationCreateMappingAttributeDeviceModelAttributeSetsArgs = {
  mappingAttributeDeviceModelAttributeSets: Array<MappingAttributeDeviceModelAttributeSetCreateType>;
};


export type MutationCreateMappingDeviceBuildingArgs = {
  mappingDeviceBuilding: MappingDeviceBuildingCreateType;
};


export type MutationCreateMappingDeviceBuildingsArgs = {
  mappingDeviceBuildings: Array<MappingDeviceBuildingCreateType>;
};


export type MutationCreateMappingDeviceImageArgs = {
  mappingDeviceImage: MappingDeviceImageCreateType;
};


export type MutationCreateMappingDeviceImagesArgs = {
  mappingDeviceImages: Array<MappingDeviceImageCreateType>;
};


export type MutationCreateMappingDeviceZoneArgs = {
  mappingDeviceZone: MappingDeviceZoneCreateType;
};


export type MutationCreateMappingDeviceZonesArgs = {
  mappingDeviceZones: Array<MappingDeviceZoneCreateType>;
};


export type MutationCreateRuleArgs = {
  rule: RuleCreateType;
};


export type MutationCreateRuleWithChecksArgs = {
  rule: RuleCreateType;
};


export type MutationCreateRulesArgs = {
  rules: Array<RuleCreateType>;
};


export type MutationCreateRulesWithChecksArgs = {
  rules: Array<RuleCreateType>;
};


export type MutationCreateScheduleArgs = {
  schedule: ScheduleInput;
};


export type MutationCreateSiteArgs = {
  site: SiteCreateType;
};


export type MutationCreateSitesArgs = {
  sites: Array<SiteCreateType>;
};


export type MutationCreateStoreyArgs = {
  storey: StoreyCreateType;
};


export type MutationCreateStoreysArgs = {
  storeys: Array<StoreyCreateType>;
};


export type MutationCreateUnitArgs = {
  unit: UnitCreateType;
};


export type MutationCreateUnitsArgs = {
  units: Array<UnitCreateType>;
};


export type MutationCreateZoneArgs = {
  zone: ZoneCreateType;
};


export type MutationCreateZonesArgs = {
  zones: Array<ZoneCreateType>;
};


export type MutationDeleteAttributeArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteAttributesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteBuildingArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteBuildingsArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteCapabilitiesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteCapabilityArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteDeviceArgs = {
  deleteChildDevices?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['UUID'];
};


export type MutationDeleteDeviceCapabilitySensorMeasurementsArgs = {
  deviceId: Scalars['UUID'];
  deviceModelCapabilityIds?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationDeleteDeviceModelArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteDeviceModelAttributeSetArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteDeviceModelAttributeSetsArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteDeviceModelCapabilitiesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteDeviceModelCapabilityArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteDeviceModelsArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteDeviceTypeArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteDeviceTypesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteDevicesArgs = {
  deleteChildDevices?: InputMaybe<Scalars['Boolean']>;
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteDevicesByGatewayIdArgs = {
  gatewayId: Scalars['UUID'];
};


export type MutationDeleteGatewayDeviceConnectionArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteGatewayDeviceConnectionsArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteImageArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteImagesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteLocationArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteLocationsArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteMappingAttributeDeviceModelAttributeSetArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteMappingAttributeDeviceModelAttributeSetsArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteMappingDeviceBuildingArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteMappingDeviceBuildingsArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteMappingDeviceImageArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteMappingDeviceImagesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteMappingDeviceZoneArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteMappingDeviceZonesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteRuleArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteRulesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteScheduleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSiteArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteSitesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteStoreyArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteStoreysArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteUnitArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteUnitsArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationDeleteZoneArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteZonesArgs = {
  ids: Array<Scalars['UUID']>;
};


export type MutationExportIoTDevicesToBlobStorageArgs = {
  blobName?: InputMaybe<Scalars['String']>;
};


export type MutationRebuildDeviceMappingsInStorageTableArgs = {
  tenantIds?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationRedoCreateGatewayDeviceNotificationArgs = {
  deviceId: Scalars['UUID'];
};


export type MutationRegenerateGatewayDeviceConnectionKeyArgs = {
  deviceId: Scalars['UUID'];
  regeneratePrimaryKey?: Scalars['Boolean'];
  regenerateSecondaryKey?: Scalars['Boolean'];
};


export type MutationRegenerateGatewayDeviceConnectionsKeysArgs = {
  deviceIds: Array<Scalars['UUID']>;
  regeneratePrimaryKey?: Scalars['Boolean'];
  regenerateSecondaryKey?: Scalars['Boolean'];
};


export type MutationRegenerateGatewayDeviceSharedAccessSignatureArgs = {
  deviceId: Scalars['UUID'];
  expirySeconds: Scalars['Int'];
};


export type MutationRegenerateTenantGatewayDeviceConnectionsKeysArgs = {
  regeneratePrimaryKey?: Scalars['Boolean'];
  regenerateSecondaryKey?: Scalars['Boolean'];
};


export type MutationRemoveDeletedDeviceMappingsInStorageTableArgs = {
  tenantIds?: InputMaybe<Array<Scalars['UUID']>>;
};


export type MutationSendContactRequestByEmailArgs = {
  request: EmailContactRequestInput;
};


export type MutationSendCustomFeatureRequestByRolesArgs = {
  request: CustomFeatureRequestInput;
};


export type MutationSendIdeaRequestByRolesArgs = {
  request: IdeaRequestInput;
};


export type MutationSendReportByEmailArgs = {
  request: ReportRequestInput;
};


export type MutationSetDeviceAttributeValueArgs = {
  attributeId: Scalars['UUID'];
  deviceId: Scalars['UUID'];
  value: Scalars['String'];
};


export type MutationSetDeviceAttributeValuesArgs = {
  deviceId: Scalars['UUID'];
  input: Array<AttributeIdAttributeValueInputType>;
};


export type MutationTestSubscriptionDeviceMessageArgs = {
  deviceId: Scalars['UUID'];
};


export type MutationUnsetDeviceAttributeValueArgs = {
  attributeId: Scalars['UUID'];
  deviceId: Scalars['UUID'];
};


export type MutationUnsetDeviceAttributeValuesArgs = {
  attributeIds: Array<Scalars['UUID']>;
  deviceId: Scalars['UUID'];
};


export type MutationUpdateAttributeArgs = {
  attribute: AttributeUpdateType;
};


export type MutationUpdateAttributesArgs = {
  attributes: Array<AttributeUpdateType>;
};


export type MutationUpdateBuildingArgs = {
  building: BuildingUpdateType;
};


export type MutationUpdateBuildingsArgs = {
  buildings: Array<BuildingUpdateType>;
};


export type MutationUpdateCapabilitiesArgs = {
  capabilities: Array<CapabilityUpdateType>;
};


export type MutationUpdateCapabilityArgs = {
  capability: CapabilityUpdateType;
};


export type MutationUpdateDeviceArgs = {
  device: DeviceUpdateType;
};


export type MutationUpdateDeviceModelArgs = {
  deviceModel: DeviceModelUpdateType;
};


export type MutationUpdateDeviceModelAttributeSetArgs = {
  deviceModelAttributeSet: DeviceModelAttributeSetUpdateType;
};


export type MutationUpdateDeviceModelAttributeSetsArgs = {
  deviceModelAttributeSets: Array<DeviceModelAttributeSetUpdateType>;
};


export type MutationUpdateDeviceModelCapabilitiesArgs = {
  deviceModelCapabilities: Array<DeviceModelCapabilityUpdateType>;
};


export type MutationUpdateDeviceModelCapabilityArgs = {
  deviceModelCapability: DeviceModelCapabilityUpdateType;
};


export type MutationUpdateDeviceModelsArgs = {
  deviceModels: Array<DeviceModelUpdateType>;
};


export type MutationUpdateDeviceTypeArgs = {
  deviceType: DeviceTypeUpdateType;
};


export type MutationUpdateDeviceTypesArgs = {
  deviceTypes: Array<DeviceTypeUpdateType>;
};


export type MutationUpdateDevicesArgs = {
  devices: Array<DeviceUpdateType>;
};


export type MutationUpdateGatewayDeviceConnectionArgs = {
  gatewayDeviceConnection: GatewayDeviceConnectionUpdateType;
};


export type MutationUpdateGatewayDeviceConnectionsArgs = {
  gatewayDeviceConnections: Array<GatewayDeviceConnectionUpdateType>;
};


export type MutationUpdateImageArgs = {
  image: ImageUpdateType;
};


export type MutationUpdateImagesArgs = {
  images: Array<ImageUpdateType>;
};


export type MutationUpdateLocationArgs = {
  location: LocationUpdateType;
};


export type MutationUpdateLocationsArgs = {
  locations: Array<LocationUpdateType>;
};


export type MutationUpdateMappingAttributeDeviceModelAttributeSetArgs = {
  mappingAttributeDeviceModelAttributeSet: MappingAttributeDeviceModelAttributeSetUpdateType;
};


export type MutationUpdateMappingAttributeDeviceModelAttributeSetsArgs = {
  mappingAttributeDeviceModelAttributeSets: Array<MappingAttributeDeviceModelAttributeSetUpdateType>;
};


export type MutationUpdateMappingDeviceBuildingArgs = {
  mappingDeviceBuilding: MappingDeviceBuildingUpdateType;
};


export type MutationUpdateMappingDeviceBuildingToBuildingArgs = {
  mappingDeviceBuildingToBuildingUpdate: MappingDeviceBuildingUpdateType;
};


export type MutationUpdateMappingDeviceBuildingToZoneArgs = {
  mappingDeviceBuildingToZoneUpdate: MappingDeviceZoneCreateType;
};


export type MutationUpdateMappingDeviceBuildingsArgs = {
  mappingDeviceBuildings: Array<MappingDeviceBuildingUpdateType>;
};


export type MutationUpdateMappingDeviceImageArgs = {
  mappingDeviceImage: MappingDeviceImageUpdateType;
};


export type MutationUpdateMappingDeviceImagesArgs = {
  mappingDeviceImages: Array<MappingDeviceImageUpdateType>;
};


export type MutationUpdateMappingDeviceZoneArgs = {
  mappingDeviceZone: MappingDeviceZoneUpdateType;
};


export type MutationUpdateMappingDeviceZoneToBuildingArgs = {
  mappingDeviceZoneToBuildingUpdate: MappingDeviceBuildingCreateType;
};


export type MutationUpdateMappingDeviceZoneToZoneArgs = {
  mappingDeviceZoneToZoneUpdate: MappingDeviceZoneUpdateType;
};


export type MutationUpdateMappingDeviceZonesArgs = {
  mappingDeviceZones: Array<MappingDeviceZoneUpdateType>;
};


export type MutationUpdatePlacementOfDeviceArgs = {
  deviceId: Scalars['UUID'];
  placementId: Scalars['UUID'];
  placementType: PlacementKind;
};


export type MutationUpdateRuleArgs = {
  rule: RuleUpdateType;
};


export type MutationUpdateRuleWithChecksArgs = {
  rule: RuleUpdateType;
};


export type MutationUpdateRulesArgs = {
  rules: Array<RuleUpdateType>;
};


export type MutationUpdateRulesWithChecksArgs = {
  rules: Array<RuleUpdateType>;
};


export type MutationUpdateScheduleArgs = {
  id: Scalars['ID'];
  schedule: ScheduleInput;
};


export type MutationUpdateSiteArgs = {
  site: SiteUpdateType;
};


export type MutationUpdateSitesArgs = {
  sites: Array<SiteUpdateType>;
};


export type MutationUpdateStoreyArgs = {
  storey: StoreyUpdateType;
};


export type MutationUpdateStoreysArgs = {
  storeys: Array<StoreyUpdateType>;
};


export type MutationUpdateStressTestMappingTablesAsyncArgs = {
  delete?: Scalars['Boolean'];
  numCapabilities: Scalars['Int'];
  numDevices: Scalars['Int'];
  numTenants: Scalars['Int'];
};


export type MutationUpdateUnitArgs = {
  unit: UnitUpdateType;
};


export type MutationUpdateUnitsArgs = {
  units: Array<UnitUpdateType>;
};


export type MutationUpdateZoneArgs = {
  zone: ZoneUpdateType;
};


export type MutationUpdateZonesArgs = {
  zones: Array<ZoneUpdateType>;
};

export type Node = {
  __typename?: 'Node';
  id: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type NodeInput = {
  id: Scalars['String'];
  type: Scalars['String'];
};

export type NodeParents = {
  __typename?: 'NodeParents';
  equipment?: Maybe<Node>;
  sensor?: Maybe<Node>;
  spaces: Array<Space>;
  subsystem?: Maybe<Subsystem>;
  system?: Maybe<System>;
};

export type NodeWithSystemType = {
  __typename?: 'NodeWithSystemType';
  node: Node;
  space: Space;
  systemType: SystemType;
};

export type NullableOfHeartbeatStatesOperationFilterInput = {
  eq?: InputMaybe<HeartbeatStates>;
  in?: InputMaybe<Array<InputMaybe<HeartbeatStates>>>;
  neq?: InputMaybe<HeartbeatStates>;
  nin?: InputMaybe<Array<InputMaybe<HeartbeatStates>>>;
};

export type Output = {
  __typename?: 'Output';
  cypherCode: Scalars['String'];
  id: Scalars['UUID'];
  name: Scalars['String'];
  objectClassType: Scalars['String'];
  objectName: Scalars['String'];
  tenantId?: Maybe<Scalars['UUID']>;
  timeSeriesId: Scalars['String'];
};

export type PlacementFilterRow = {
  __typename?: 'PlacementFilterRow';
  buildingId?: Maybe<Scalars['UUID']>;
  deviceId?: Maybe<Scalars['UUID']>;
  locationId?: Maybe<Scalars['UUID']>;
  mappingPlacementId?: Maybe<Scalars['UUID']>;
  siteId?: Maybe<Scalars['UUID']>;
  storeyId?: Maybe<Scalars['UUID']>;
  zoneId?: Maybe<Scalars['UUID']>;
};

export type PlacementFilterRowFilterInput = {
  and?: InputMaybe<Array<PlacementFilterRowFilterInput>>;
  buildingId?: InputMaybe<UuidOperationFilterInput>;
  deviceId?: InputMaybe<UuidOperationFilterInput>;
  locationId?: InputMaybe<UuidOperationFilterInput>;
  mappingPlacementId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<PlacementFilterRowFilterInput>>;
  siteId?: InputMaybe<UuidOperationFilterInput>;
  storeyId?: InputMaybe<UuidOperationFilterInput>;
  zoneId?: InputMaybe<UuidOperationFilterInput>;
};

export type PlacementFilterRowSortInput = {
  buildingId?: InputMaybe<SortEnumType>;
  deviceId?: InputMaybe<SortEnumType>;
  locationId?: InputMaybe<SortEnumType>;
  mappingPlacementId?: InputMaybe<SortEnumType>;
  siteId?: InputMaybe<SortEnumType>;
  storeyId?: InputMaybe<SortEnumType>;
  zoneId?: InputMaybe<SortEnumType>;
};

export enum PlacementKind {
  Building = 'BUILDING',
  Zone = 'ZONE'
}

export type PlacementOfDevice = {
  __typename?: 'PlacementOfDevice';
  buildingCity?: Maybe<Scalars['String']>;
  buildingId?: Maybe<Scalars['UUID']>;
  buildingName?: Maybe<Scalars['String']>;
  buildingNumber?: Maybe<Scalars['String']>;
  buildingStreet?: Maybe<Scalars['String']>;
  buildingZip?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['UUID']>;
  locationCity?: Maybe<Scalars['String']>;
  locationCountry?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['UUID']>;
  mappingPlacementId?: Maybe<Scalars['UUID']>;
  mappingPlacementType?: Maybe<Scalars['String']>;
  siteCity?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['UUID']>;
  siteName?: Maybe<Scalars['String']>;
  siteNumber?: Maybe<Scalars['String']>;
  siteStreet?: Maybe<Scalars['String']>;
  siteZip?: Maybe<Scalars['String']>;
  storeyId?: Maybe<Scalars['UUID']>;
  storeyName?: Maybe<Scalars['String']>;
  zoneId?: Maybe<Scalars['UUID']>;
  zoneName?: Maybe<Scalars['String']>;
};

export type Point = {
  __typename?: 'Point';
  id: Scalars['String'];
  name: Scalars['String'];
  timeseriesId: Scalars['String'];
  type: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  aggregates: Array<Aggregate>;
  attribute?: Maybe<Attribute>;
  attributes?: Maybe<AttributesCollectionSegment>;
  /** Queries all attributes assigned to devices in a building */
  attributesByBuildings?: Maybe<AttributesByBuildingsCollectionSegment>;
  auditLog?: Maybe<AuditLog>;
  /** Audit Logs */
  auditLogs?: Maybe<AuditLogsCollectionSegment>;
  building?: Maybe<Building>;
  buildingFilterTable?: Maybe<BuildingFilterTableCollectionSegment>;
  buildings?: Maybe<BuildingsCollectionSegment>;
  capabilities?: Maybe<CapabilitiesCollectionSegment>;
  capability?: Maybe<Capability>;
  createDemoSite: Scalars['Boolean'];
  /** Get DataService Info */
  dataServiceInfo?: Maybe<ServiceInfo>;
  device?: Maybe<Device>;
  deviceAttributeValue?: Maybe<DeviceAttributeValue>;
  deviceAttributeValues?: Maybe<DeviceAttributeValuesCollectionSegment>;
  deviceGroupedSensorDataByViews?: Maybe<Array<Maybe<TimeAndAggregationParameter>>>;
  deviceModel?: Maybe<DeviceModel>;
  deviceModelAttributeSet?: Maybe<DeviceModelAttributeSet>;
  deviceModelAttributeSets?: Maybe<DeviceModelAttributeSetsCollectionSegment>;
  deviceModelCapabilities?: Maybe<DeviceModelCapabilitiesCollectionSegment>;
  deviceModelCapability?: Maybe<DeviceModelCapability>;
  deviceModels?: Maybe<DeviceModelsCollectionSegment>;
  deviceType?: Maybe<DeviceType>;
  deviceTypes?: Maybe<DeviceTypesCollectionSegment>;
  deviceWithAttributes?: Maybe<DeviceWithAttributeValues>;
  devices?: Maybe<DevicesCollectionSegment>;
  /** Queries all devices in a building */
  devicesByBuildings?: Maybe<DevicesByBuildingsCollectionSegment>;
  fakeErrors: Array<TestError>;
  gatewayDeviceConnection?: Maybe<GatewayDeviceConnection>;
  gatewayDeviceConnections?: Maybe<GatewayDeviceConnectionsCollectionSegment>;
  gatewayDevices?: Maybe<Array<Maybe<Device>>>;
  image?: Maybe<Image>;
  images?: Maybe<ImagesCollectionSegment>;
  latestSensorData?: Maybe<Array<Maybe<DataPoint>>>;
  latestTimeseriesData?: Maybe<Array<Maybe<TimeseriesDataPoint>>>;
  location?: Maybe<Location>;
  locations?: Maybe<LocationsCollectionSegment>;
  mappingAttributeDeviceModelAttributeSet?: Maybe<MappingAttributeDeviceModelAttributeSet>;
  mappingAttributeDeviceModelAttributeSets?: Maybe<MappingAttributeDeviceModelAttributeSetsCollectionSegment>;
  mappingDeviceBuilding?: Maybe<MappingDeviceBuilding>;
  mappingDeviceBuildings?: Maybe<MappingDeviceBuildingsCollectionSegment>;
  mappingDeviceImage?: Maybe<MappingDeviceImage>;
  mappingDeviceImages?: Maybe<MappingDeviceImagesCollectionSegment>;
  mappingDeviceZone?: Maybe<MappingDeviceZone>;
  mappingDeviceZones?: Maybe<MappingDeviceZonesCollectionSegment>;
  multipleDeviceSensorDataByViews?: Maybe<Array<Maybe<TimeAndAggregationParameter>>>;
  /** Get Info of multi sensor measurements */
  multipleDeviceSensorDataInfo?: Maybe<SensorMeasurementsInfo>;
  multipleTimeseriesData?: Maybe<TimeseriesTimeAndAggregationParameter>;
  nodeParents: Array<NodeParents>;
  nodesSystemType: Array<NodeWithSystemType>;
  placementOfDevice?: Maybe<PlacementOfDevice>;
  resolveRules: Array<Rule>;
  resolveRulesByExternalUri: Array<Rule>;
  resolveSpaceSensorTimeseries: Array<SpaceSensorTimeseries>;
  resolveTimeseriesId: Scalars['UUID'];
  resolveTimeseriesIdsByScoreTypes: Array<TimeseriesIdScoreType>;
  rule?: Maybe<Rule>;
  ruleAlarmQuery: RuleAlarmWithHistory;
  ruleAlarmsBySpaceQuery: Array<RuleAlarm>;
  rules?: Maybe<RulesCollectionSegment>;
  rulesByIds: Array<Rule>;
  /** Fetch a single schedule by its ID. */
  schedule?: Maybe<Schedule>;
  /** Fetch all schedules or filter by type. */
  schedules: Array<Schedule>;
  /** Queries for timeseries data by score and space ID */
  scoreTimeseriesBySpace: TimeseriesDataBySpaceResult;
  /** Get list of sensor measurements */
  sensorMeasurements?: Maybe<SensorMeasurementsCollectionSegment>;
  /** Get Info of sensor measurement */
  sensorMeasurementsInfo?: Maybe<SensorMeasurementsInfo>;
  /** Queries for timeseries data by sensor type and space ID */
  sensorTimeseriesBySpaceAndType: TimeseriesDataBySpaceAndSensorResult;
  site?: Maybe<Site>;
  sites?: Maybe<SitesCollectionSegment>;
  spaceChildren: Array<Node>;
  spaceSystems: Array<System>;
  spaceTree: Array<SpaceNode>;
  spacesSensorsByType: Array<SpaceSensorsWithParents>;
  spacesStatistics: Array<SpacesStatistics>;
  storey?: Maybe<Storey>;
  storeys?: Maybe<StoreysCollectionSegment>;
  subsystemInstallationParts: Array<CategorizedInstallationParts>;
  subsystemInstallationPartsScores: Array<CategorizedInstallationPartsScores>;
  subsystemsScoreBySpace: Array<SubsystemsScore>;
  /** Queries for systems score by space ID */
  systemsScoreBySpace: Array<GroupedSystemsScore>;
  /** Get tenant contact list */
  tenantContacts: Array<User>;
  unit?: Maybe<Unit>;
  units?: Maybe<UnitsCollectionSegment>;
  weatherInfoByCoordinates: ForecastResult;
  zone?: Maybe<Zone>;
  zoneAndBuildingFilterTable?: Maybe<ZoneAndBuildingFilterTableCollectionSegment>;
  zoneFilterTable?: Maybe<ZoneFilterTableCollectionSegment>;
  zones?: Maybe<ZonesCollectionSegment>;
};


export type QueryAggregatesArgs = {
  externalId: Scalars['String'];
};


export type QueryAttributeArgs = {
  id: Scalars['UUID'];
};


export type QueryAttributesArgs = {
  order?: InputMaybe<Array<AttributeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AttributeFilterInput>;
};


export type QueryAttributesByBuildingsArgs = {
  order?: InputMaybe<Array<AttributesByBuildingsSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AttributesByBuildingsFilterInput>;
};


export type QueryAuditLogArgs = {
  id: Scalars['UUID'];
};


export type QueryAuditLogsArgs = {
  order?: InputMaybe<Array<AuditLogSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AuditLogFilterInput>;
};


export type QueryBuildingArgs = {
  id: Scalars['UUID'];
};


export type QueryBuildingFilterTableArgs = {
  order?: InputMaybe<Array<PlacementFilterRowSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlacementFilterRowFilterInput>;
};


export type QueryBuildingsArgs = {
  order?: InputMaybe<Array<BuildingSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BuildingFilterInput>;
};


export type QueryCapabilitiesArgs = {
  order?: InputMaybe<Array<CapabilitySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CapabilityFilterInput>;
};


export type QueryCapabilityArgs = {
  id: Scalars['UUID'];
};


export type QueryCreateDemoSiteArgs = {
  demoSiteType: DemoSiteType;
};


export type QueryDeviceArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceAttributeValueArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceAttributeValuesArgs = {
  order?: InputMaybe<Array<DeviceAttributeValueSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceAttributeValueFilterInput>;
};


export type QueryDeviceGroupedSensorDataByViewsArgs = {
  where?: InputMaybe<QueryGroupFiltersMultipleInput>;
};


export type QueryDeviceModelArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceModelAttributeSetArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceModelAttributeSetsArgs = {
  order?: InputMaybe<Array<DeviceModelAttributeSetSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceModelAttributeSetFilterInput>;
};


export type QueryDeviceModelCapabilitiesArgs = {
  order?: InputMaybe<Array<DeviceModelCapabilitySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceModelCapabilityFilterInput>;
};


export type QueryDeviceModelCapabilityArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceModelsArgs = {
  order?: InputMaybe<Array<DeviceModelSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceModelFilterInput>;
};


export type QueryDeviceTypeArgs = {
  id: Scalars['UUID'];
};


export type QueryDeviceTypesArgs = {
  order?: InputMaybe<Array<DeviceTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceTypeFilterInput>;
};


export type QueryDeviceWithAttributesArgs = {
  id: Scalars['UUID'];
};


export type QueryDevicesArgs = {
  order?: InputMaybe<Array<DeviceSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceFilterInput>;
};


export type QueryDevicesByBuildingsArgs = {
  order?: InputMaybe<Array<DevicesByBuildingSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DevicesByBuildingFilterInput>;
};


export type QueryGatewayDeviceConnectionArgs = {
  id: Scalars['UUID'];
};


export type QueryGatewayDeviceConnectionsArgs = {
  order?: InputMaybe<Array<GatewayDeviceConnectionSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GatewayDeviceConnectionFilterInput>;
};


export type QueryImageArgs = {
  id: Scalars['UUID'];
};


export type QueryImagesArgs = {
  order?: InputMaybe<Array<ImageSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ImageFilterInput>;
};


export type QueryLatestSensorDataArgs = {
  deviceId: Scalars['UUID'];
  deviceModelCapabilityIds?: InputMaybe<Array<Scalars['UUID']>>;
  limitPerCapability?: InputMaybe<Scalars['Int']>;
};


export type QueryLatestTimeseriesDataArgs = {
  limitPerTimeseries?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TimeseriesQueryFiltersInput>;
};


export type QueryLocationArgs = {
  id: Scalars['UUID'];
};


export type QueryLocationsArgs = {
  order?: InputMaybe<Array<LocationSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LocationFilterInput>;
};


export type QueryMappingAttributeDeviceModelAttributeSetArgs = {
  id: Scalars['UUID'];
};


export type QueryMappingAttributeDeviceModelAttributeSetsArgs = {
  order?: InputMaybe<Array<MappingAttributeDeviceModelAttributeSetSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingAttributeDeviceModelAttributeSetFilterInput>;
};


export type QueryMappingDeviceBuildingArgs = {
  id: Scalars['UUID'];
};


export type QueryMappingDeviceBuildingsArgs = {
  order?: InputMaybe<Array<MappingDeviceBuildingSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceBuildingFilterInput>;
};


export type QueryMappingDeviceImageArgs = {
  id: Scalars['UUID'];
};


export type QueryMappingDeviceImagesArgs = {
  order?: InputMaybe<Array<MappingDeviceImageSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceImageFilterInput>;
};


export type QueryMappingDeviceZoneArgs = {
  id: Scalars['UUID'];
};


export type QueryMappingDeviceZonesArgs = {
  order?: InputMaybe<Array<MappingDeviceZoneSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MappingDeviceZoneFilterInput>;
};


export type QueryMultipleDeviceSensorDataByViewsArgs = {
  where?: InputMaybe<QueryFiltersMultipleInput>;
};


export type QueryMultipleDeviceSensorDataInfoArgs = {
  queryFilters?: InputMaybe<Array<InputMaybe<QueryFiltersMultipleDeviceAndCapabilityInput>>>;
};


export type QueryMultipleTimeseriesDataArgs = {
  where?: InputMaybe<QueryFiltersMultipleTimeseriesInput>;
};


export type QueryNodeParentsArgs = {
  where: QueryNodeParentsInput;
};


export type QueryNodesSystemTypeArgs = {
  where: QueryNodesSystemTypesInput;
};


export type QueryPlacementOfDeviceArgs = {
  deviceId: Scalars['UUID'];
};


export type QueryResolveRulesArgs = {
  where: QueryRulesInput;
};


export type QueryResolveRulesByExternalUriArgs = {
  where: QueryRulesByExternalUriInput;
};


export type QueryResolveSpaceSensorTimeseriesArgs = {
  where: QuerySpaceSensorInput;
};


export type QueryResolveTimeseriesIdArgs = {
  externalId: Scalars['String'];
  selector: SelectorInput;
};


export type QueryResolveTimeseriesIdsByScoreTypesArgs = {
  where: QueryResolveTimeseriesInput;
};


export type QueryRuleArgs = {
  id: Scalars['UUID'];
};


export type QueryRuleAlarmQueryArgs = {
  where: RuleAlarmsByIdInput;
};


export type QueryRuleAlarmsBySpaceQueryArgs = {
  where: RuleAlarmsBySpaceInput;
};


export type QueryRulesArgs = {
  order?: InputMaybe<Array<RuleSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RuleFilterInput>;
};


export type QueryRulesByIdsArgs = {
  where: QueryRulesByIdInput;
};


export type QueryScheduleArgs = {
  id: Scalars['ID'];
};


export type QuerySchedulesArgs = {
  type: ScheduleType;
};


export type QueryScoreTimeseriesBySpaceArgs = {
  where: ScoreTimeseriesBySpaceInput;
};


export type QuerySensorMeasurementsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryFiltersInput>;
};


export type QuerySensorMeasurementsInfoArgs = {
  where?: InputMaybe<SensorInfoQueryFilterInput>;
};


export type QuerySensorTimeseriesBySpaceAndTypeArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SortInput>;
  where: SensorTimeseriesBySpaceInput;
};


export type QuerySiteArgs = {
  id: Scalars['UUID'];
};


export type QuerySitesArgs = {
  order?: InputMaybe<Array<SiteSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SiteFilterInput>;
};


export type QuerySpaceChildrenArgs = {
  where: QuerySpaceSystemCategoriesInput;
};


export type QuerySpaceSystemsArgs = {
  where: QuerySpaceSystemCategoriesInput;
};


export type QuerySpaceTreeArgs = {
  where?: InputMaybe<QuerySpaceTreeInput>;
};


export type QuerySpacesSensorsByTypeArgs = {
  where: QuerySpacesByTypeInput;
};


export type QuerySpacesStatisticsArgs = {
  where: SpacesStatisticsInput;
};


export type QueryStoreyArgs = {
  id: Scalars['UUID'];
};


export type QueryStoreysArgs = {
  order?: InputMaybe<Array<StoreySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<StoreyFilterInput>;
};


export type QuerySubsystemInstallationPartsArgs = {
  where: QuerySubsystemInput;
};


export type QuerySubsystemInstallationPartsScoresArgs = {
  where: SubsystemInstallationPartsScoresInput;
};


export type QuerySubsystemsScoreBySpaceArgs = {
  where: SubsystemsScoresBySpaceInput;
};


export type QuerySystemsScoreBySpaceArgs = {
  where: SystemsScoresBySpaceInput;
};


export type QueryUnitArgs = {
  id: Scalars['UUID'];
};


export type QueryUnitsArgs = {
  order?: InputMaybe<Array<UnitSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UnitFilterInput>;
};


export type QueryWeatherInfoByCoordinatesArgs = {
  where: QueryWeatherInput;
};


export type QueryZoneArgs = {
  id: Scalars['UUID'];
};


export type QueryZoneAndBuildingFilterTableArgs = {
  order?: InputMaybe<Array<PlacementFilterRowSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlacementFilterRowFilterInput>;
};


export type QueryZoneFilterTableArgs = {
  order?: InputMaybe<Array<PlacementFilterRowSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlacementFilterRowFilterInput>;
};


export type QueryZonesArgs = {
  order?: InputMaybe<Array<ZoneSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ZoneFilterInput>;
};

export type QueryFiltersInput = {
  deviceId: Scalars['UUID'];
  deviceModelCapabilityIds: Array<Scalars['UUID']>;
  limit?: InputMaybe<Scalars['Int']>;
  readFromUtc?: InputMaybe<Scalars['DateTime']>;
  readUntilUtc?: InputMaybe<Scalars['DateTime']>;
};

export type QueryFiltersMultipleDeviceAndCapabilityInput = {
  deviceId: Scalars['UUID'];
  deviceModelCapabilityIds?: InputMaybe<Array<InputMaybe<DeviceModelCapabilityIdsInput>>>;
};

export type QueryFiltersMultipleInput = {
  aggregationIntervalTypeActive?: InputMaybe<AggregationIntervals>;
  aggregationType?: InputMaybe<AggregationTypes>;
  queryFiltersMultipleDeviceAndCapability?: InputMaybe<Array<InputMaybe<QueryFiltersMultipleDeviceAndCapabilityInput>>>;
  readFromUtc?: InputMaybe<Scalars['DateTime']>;
  readUntilNow?: InputMaybe<Scalars['Boolean']>;
  readUntilUtc?: InputMaybe<Scalars['DateTime']>;
  timeSpan?: InputMaybe<TimeSpans>;
};

export type QueryFiltersMultipleTimeseriesInput = {
  aggregationIntervalTypeActive?: InputMaybe<AggregationIntervals>;
  aggregationType?: InputMaybe<AggregationTypes>;
  readFromUtc?: InputMaybe<Scalars['DateTime']>;
  readUntilNow?: InputMaybe<Scalars['Boolean']>;
  readUntilUtc?: InputMaybe<Scalars['DateTime']>;
  timeSpan?: InputMaybe<TimeSpans>;
  timeseriesIds?: InputMaybe<Array<Scalars['UUID']>>;
};

export type QueryGroupFiltersMultipleInput = {
  aggregationIntervalTypeActive?: InputMaybe<AggregationIntervals>;
  aggregationType?: InputMaybe<AggregationTypes>;
  deviceModelCapabilityId: Scalars['UUID'];
  limit: Scalars['Int'];
  readFromUtc?: InputMaybe<Scalars['DateTime']>;
  readUntilNow?: InputMaybe<Scalars['Boolean']>;
  readUntilUtc?: InputMaybe<Scalars['DateTime']>;
  timeSpan?: InputMaybe<TimeSpans>;
};

export type QueryNodeParentsInput = {
  uri: Scalars['String'];
};

export type QueryNodesSystemTypesInput = {
  systemTypes: Array<SystemType>;
  uris: Array<Scalars['String']>;
};

export type QueryResolveTimeseriesInput = {
  externalIdScoreTypes: Array<ExternalIdScoreTypesInput>;
};

export type QueryRulesByExternalUriInput = {
  alarmTypes: Array<AlarmType>;
  externalUri?: InputMaybe<Scalars['String']>;
  severities: Array<Severity>;
};

export type QueryRulesByIdInput = {
  ruleIds: Array<Scalars['String']>;
};

export type QueryRulesInput = {
  alarmTypes: Array<AlarmType>;
  externalIds: Array<Scalars['String']>;
  severities: Array<Severity>;
};

export type QuerySpaceSensorInput = {
  resolutionType: ResolutionType;
  sensorType: SensorType;
  space: SpaceInput;
};

export type QuerySpaceSystemCategoriesInput = {
  space: SpaceInput;
  systemTypes: Array<SystemType>;
};

export type QuerySpaceTreeInput = {
  maxTreeDepth?: InputMaybe<Scalars['Int']>;
  space?: InputMaybe<SpaceInput>;
};

export type QuerySpacesByTypeInput = {
  sensorTypes: Array<SensorType>;
  space: SpaceInput;
  spaceType: SpaceType;
};

export type QuerySubsystemInput = {
  categories: Array<CategoryType>;
  subsystem: NodeInput;
};

export type QueryWeatherInput = {
  language: Scalars['String'];
  latitude: Scalars['String'];
  longitude: Scalars['String'];
};

/** Report Request object */
export type ReportRequestInput = {
  /** File converted as base64 string */
  data: Scalars['String'];
  /** User's emails that should receive notification */
  emails: Array<Scalars['String']>;
  /** Report name */
  reportName: Scalars['String'];
};

export enum ResolutionType {
  TowardsLeaf = 'TOWARDS_LEAF',
  TowardsRoot = 'TOWARDS_ROOT',
  TowardsRootAndLeaf = 'TOWARDS_ROOT_AND_LEAF'
}

export type Role = {
  __typename?: 'Role';
  id: Scalars['UUID'];
  name: Scalars['String'];
};

export type Rule = {
  __typename?: 'Rule';
  createdAt?: Maybe<Scalars['DateTime']>;
  deployedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  json?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['UUID']>;
};

export type RuleAlarm = {
  __typename?: 'RuleAlarm';
  alarmType: AlarmType;
  description: Scalars['String'];
  id: Scalars['String'];
  lastUpdateTimestamp?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  severity: Severity;
  space: Space;
  state: AlarmState;
  systemType: SystemType;
};

export type RuleAlarmFiltersInput = {
  alarmState?: InputMaybe<AlarmState>;
  alarmTypes: Array<AlarmType>;
  severities: Array<Severity>;
  systemTypes: Array<SystemType>;
};

export type RuleAlarmWithHistory = {
  __typename?: 'RuleAlarmWithHistory';
  alarmType: AlarmType;
  description: Scalars['String'];
  id: Scalars['String'];
  lastUpdateTimestamp?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  parents: NodeParents;
  severity: Severity;
  state: AlarmState;
  stateHistory: Array<AlarmStateHistoryItem>;
};

export type RuleAlarmsByIdInput = {
  dataFilters: DataQueryFiltersInput;
  ruleId: Scalars['String'];
};

export type RuleAlarmsBySpaceInput = {
  dataFilters: DataQueryFiltersInput;
  ruleAlarmFilters: RuleAlarmFiltersInput;
  space: SpaceInput;
};

export type RuleCreateType = {
  /** Rule Definition as JSON */
  json?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=512 */
  name?: InputMaybe<Scalars['String']>;
};

export type RuleFilterInput = {
  and?: InputMaybe<Array<RuleFilterInput>>;
  createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  deployedAt?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  json?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RuleFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type RuleSetNullInputType = {
  json?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
};

export type RuleSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  deployedAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  json?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type RuleUpdateType = {
  id: Scalars['UUID'];
  /** Rule Definition as JSON */
  json?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
};

export type RuleWithChecks = {
  __typename?: 'RuleWithChecks';
  createdAt?: Maybe<Scalars['DateTime']>;
  deployedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  json?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['UUID']>;
};

/** A segment of a collection. */
export type RulesCollectionSegment = {
  __typename?: 'RulesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Maybe<Rule>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type Schedule = {
  __typename?: 'Schedule';
  channels: Array<ScheduleChannel>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  params: Array<ScheduleParam>;
  setting: ScheduleSetting;
  type: ScheduleType;
};

export type ScheduleChannel = {
  __typename?: 'ScheduleChannel';
  type: ScheduleChannelType;
  value: Scalars['String'];
};

export type ScheduleChannelInput = {
  type: ScheduleChannelType;
  value: Scalars['String'];
};

export enum ScheduleChannelType {
  Email = 'EMAIL'
}

export type ScheduleInput = {
  channels: Array<ScheduleChannelInput>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  params?: InputMaybe<Array<ScheduleParamInput>>;
  setting: ScheduleSettingInput;
  type: ScheduleType;
};

export type ScheduleParam = {
  __typename?: 'ScheduleParam';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ScheduleParamInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ScheduleSetting = {
  __typename?: 'ScheduleSetting';
  dayOfMonth?: Maybe<Scalars['Int']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  frequency: ScheduleSettingFrequency;
  time: Scalars['String'];
};

export enum ScheduleSettingFrequency {
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export type ScheduleSettingInput = {
  dayOfMonth?: InputMaybe<Scalars['Int']>;
  dayOfWeek?: InputMaybe<Scalars['Int']>;
  frequency: ScheduleSettingFrequency;
  time?: InputMaybe<Scalars['String']>;
};

export enum ScheduleType {
  Report = 'REPORT'
}

export type Score = {
  __typename?: 'Score';
  scoreType: ScoreType;
  value?: Maybe<Scalars['Float']>;
};

export type ScoreData = {
  __typename?: 'ScoreData';
  average?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  scoreType: ScoreType;
};

export type ScoreTimeseriesBySpaceInput = {
  aggregationScoreType: ScoreType;
  dataFilters: DataQueryFiltersInput;
  spaceId: Scalars['String'];
  spaceType: SpaceType;
};

export enum ScoreType {
  Accuracy = 'ACCURACY',
  Co2 = 'CO2',
  Humidity = 'HUMIDITY',
  HumidityDeviation = 'HUMIDITY_DEVIATION',
  OnlineStatus = 'ONLINE_STATUS',
  OperatingHours = 'OPERATING_HOURS',
  Stability = 'STABILITY',
  StartStopCount = 'START_STOP_COUNT',
  Temperature = 'TEMPERATURE',
  TemperatureDeviation = 'TEMPERATURE_DEVIATION'
}

export type ScoreTypeAggregationInput = {
  aggregationTypeOverride?: InputMaybe<AggregationTypes>;
  scoreType: ScoreType;
};

export type SelectorInput = {
  scoreType: ScoreType;
};

export type SensorInfoQueryFilterInput = {
  deviceId: Scalars['UUID'];
  deviceModelCapabilityId: Scalars['UUID'];
};

export type SensorMeasurement = {
  __typename?: 'SensorMeasurement';
  device: Device;
  deviceId: Scalars['UUID'];
  deviceModelCapability?: Maybe<DeviceModelCapability>;
  deviceModelCapabilityId: Scalars['UUID'];
  ingestedAt?: Maybe<Scalars['DateTime']>;
  insertedAt?: Maybe<Scalars['DateTime']>;
  measuredAt?: Maybe<Scalars['DateTime']>;
  measuredTimeZone?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['UUID']>;
  utcTimeMeasured: Scalars['DateTime'];
  valueBool?: Maybe<Scalars['Boolean']>;
  valueDate?: Maybe<Scalars['DateTime']>;
  valueDateTime?: Maybe<Scalars['DateTime']>;
  valueDecimal?: Maybe<Scalars['Decimal']>;
  valueDouble?: Maybe<Scalars['Float']>;
  valueFloat?: Maybe<Scalars['Float']>;
  valueInteger?: Maybe<Scalars['Int']>;
  valueString?: Maybe<Scalars['String']>;
};

/** A segment of a collection. */
export type SensorMeasurementsCollectionSegment = {
  __typename?: 'SensorMeasurementsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<SensorMeasurement>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type SensorMeasurementsInfo = {
  __typename?: 'SensorMeasurementsInfo';
  count: Scalars['Int'];
  maxUtcTimeMeasured: Scalars['DateTime'];
  minUtcTimeMeasured: Scalars['DateTime'];
};

export type SensorTimeseriesBySpaceInput = {
  dataFilters: DataQueryFiltersInput;
  resolutionType: ResolutionType;
  sensorType: SensorType;
  spaceId: Scalars['String'];
  spaceType: SpaceType;
};

export enum SensorType {
  Co2Sensor = 'CO2_SENSOR',
  IndoorAirCo2Sensor = 'INDOOR_AIR_CO2_SENSOR',
  IndoorAirHumiditySensor = 'INDOOR_AIR_HUMIDITY_SENSOR',
  IndoorAirTemperatureSensor = 'INDOOR_AIR_TEMPERATURE_SENSOR',
  OutsideAirTemperatureSensor = 'OUTSIDE_AIR_TEMPERATURE_SENSOR'
}

export type ServiceInfo = {
  __typename?: 'ServiceInfo';
  dateTimeNow: Scalars['DateTime'];
  daylightSavingTimeName?: Maybe<Scalars['String']>;
  env?: Maybe<Scalars['String']>;
  utcNow: Scalars['DateTime'];
};

export enum Severity {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export type SharedAccessSignatureData = {
  __typename?: 'SharedAccessSignatureData';
  sharedAccessSignature: Scalars['String'];
  sharedAccessSignatureExpiry: Scalars['DateTime'];
};

/** I am a generated type! */
export type Site = {
  __typename?: 'Site';
  buildings: Array<Building>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  image?: Maybe<Image>;
  imageId?: Maybe<Scalars['UUID']>;
  inverseParentSite: Array<Site>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  parentSite?: Maybe<Site>;
  parentSiteId?: Maybe<Scalars['UUID']>;
  tenantId?: Maybe<Scalars['UUID']>;
};


/** I am a generated type! */
export type SiteBuildingsArgs = {
  order?: InputMaybe<Array<BuildingSortInput>>;
  where?: InputMaybe<BuildingFilterInput>;
};


/** I am a generated type! */
export type SiteInverseParentSiteArgs = {
  order?: InputMaybe<Array<SiteSortInput>>;
  where?: InputMaybe<SiteFilterInput>;
};

export type SiteCreateType = {
  description?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['UUID']>;
  locationId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  parentSiteId?: InputMaybe<Scalars['UUID']>;
};

export type SiteFilterInput = {
  and?: InputMaybe<Array<SiteFilterInput>>;
  buildings?: InputMaybe<ListFilterInputTypeOfBuildingFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  image?: InputMaybe<ImageFilterInput>;
  imageId?: InputMaybe<UuidOperationFilterInput>;
  inverseParentSite?: InputMaybe<ListFilterInputTypeOfSiteFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  location?: InputMaybe<LocationFilterInput>;
  locationId?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SiteFilterInput>>;
  parentSite?: InputMaybe<SiteFilterInput>;
  parentSiteId?: InputMaybe<UuidOperationFilterInput>;
  siteId?: InputMaybe<UuidOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
};

export type SiteSetNullInputType = {
  buildings?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<Scalars['Boolean']>;
  imageId?: InputMaybe<Scalars['Boolean']>;
  inverseParentSite?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Boolean']>;
  parentSite?: InputMaybe<Scalars['Boolean']>;
  parentSiteId?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type SiteSortInput = {
  description?: InputMaybe<SortEnumType>;
  image?: InputMaybe<ImageSortInput>;
  imageId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  location?: InputMaybe<LocationSortInput>;
  locationId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  parentSite?: InputMaybe<SiteSortInput>;
  parentSiteId?: InputMaybe<SortEnumType>;
  siteId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type SiteUpdateType = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  imageId?: InputMaybe<Scalars['UUID']>;
  locationId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
  parentSiteId?: InputMaybe<Scalars['UUID']>;
};

/** A segment of a collection. */
export type SitesCollectionSegment = {
  __typename?: 'SitesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Site>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export enum SortDirection {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SortInput = {
  direction?: InputMaybe<SortDirection>;
};

export type Space = {
  __typename?: 'Space';
  id: Scalars['String'];
  location?: Maybe<SpaceLocation>;
  name: Scalars['String'];
  type: SpaceType;
};

export type SpaceInput = {
  id: Scalars['String'];
  type: SpaceType;
};

export type SpaceLocation = {
  __typename?: 'SpaceLocation';
  coordinates?: Maybe<Coordinates>;
};

export type SpaceNode = {
  __typename?: 'SpaceNode';
  id: Scalars['String'];
  location?: Maybe<SpaceLocation>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  type: SpaceType;
};

export type SpaceSensorTimeseries = {
  __typename?: 'SpaceSensorTimeseries';
  sensorType: SensorType;
  space: Space;
  /** @deprecated Use the `space` field instead */
  spaceId: Scalars['String'];
  /** @deprecated Use the `space` field instead */
  spaceName: Scalars['String'];
  /** @deprecated Use the `space` field instead */
  spaceType: SpaceType;
  timeseriesId: Scalars['UUID'];
};

export type SpaceSensorsWithParents = {
  __typename?: 'SpaceSensorsWithParents';
  parents: Array<Space>;
  sensors: Array<Point>;
  space: Space;
};

export type SpaceTimeseriesInfo = {
  __typename?: 'SpaceTimeseriesInfo';
  data: Array<AggregatedData>;
  space: Space;
  /** @deprecated Use the `space` field instead */
  spaceId: Scalars['String'];
  /** @deprecated Use the `space` field instead */
  spaceName: Scalars['String'];
  /** @deprecated Use the `space` field instead */
  spaceType: SpaceType;
  timeseriesId: Scalars['UUID'];
};

export enum SpaceType {
  Building = 'BUILDING',
  Level = 'LEVEL',
  Room = 'ROOM',
  Site = 'SITE',
  Space = 'SPACE',
  SubBuilding = 'SUB_BUILDING'
}

export type SpacesStatistics = {
  __typename?: 'SpacesStatistics';
  scoreData: Array<ScoreData>;
  space: Space;
  spaces: Array<Space>;
};

export type SpacesStatisticsInput = {
  dataFilters: DataQueryFiltersInput;
  scoreTypes: Array<ScoreType>;
  space: SpaceInput;
  spaceType: SpaceType;
};

/** I am a generated type! */
export type Storey = {
  __typename?: 'Storey';
  building: Building;
  buildingId: Scalars['UUID'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  image?: Maybe<Image>;
  imageId?: Maybe<Scalars['UUID']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tenantId?: Maybe<Scalars['UUID']>;
  zones: Array<Zone>;
};


/** I am a generated type! */
export type StoreyZonesArgs = {
  order?: InputMaybe<Array<ZoneSortInput>>;
  where?: InputMaybe<ZoneFilterInput>;
};

export type StoreyCreateType = {
  buildingId: Scalars['UUID'];
  description?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
};

export type StoreyFilterInput = {
  and?: InputMaybe<Array<StoreyFilterInput>>;
  building?: InputMaybe<BuildingFilterInput>;
  buildingId?: InputMaybe<UuidOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  image?: InputMaybe<ImageFilterInput>;
  imageId?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<StoreyFilterInput>>;
  storeyId?: InputMaybe<UuidOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  zones?: InputMaybe<ListFilterInputTypeOfZoneFilterInput>;
};

export type StoreySetNullInputType = {
  building?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<Scalars['Boolean']>;
  imageId?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
  zones?: InputMaybe<Scalars['Boolean']>;
};

export type StoreySortInput = {
  building?: InputMaybe<BuildingSortInput>;
  buildingId?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  image?: InputMaybe<ImageSortInput>;
  imageId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  storeyId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
};

export type StoreyUpdateType = {
  buildingId?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  imageId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
};

/** A segment of a collection. */
export type StoreysCollectionSegment = {
  __typename?: 'StoreysCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Storey>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ncontains?: InputMaybe<Scalars['String']>;
  nendsWith?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nstartsWith?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type SubSystem = {
  __typename?: 'SubSystem';
  id: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  commissioningStatusChanged?: Maybe<Gateway_Gateway_Gateway>;
  /** Subscription providing updates when a device's connection state is changed. */
  deviceStateChanged: DeviceMessage;
};


export type SubscriptionCommissioningStatusChangedArgs = {
  gatewayIds?: InputMaybe<Array<Scalars['String']>>;
};


export type SubscriptionDeviceStateChangedArgs = {
  deviceId: Scalars['UUID'];
};

export type Subsystem = {
  __typename?: 'Subsystem';
  id: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type SubsystemInstallationPartsScoresInput = {
  categoryScores: Array<CategoryScoresInput>;
  dataFilters: DataQueryFiltersInput;
  subsystem: NodeInput;
};

export type SubsystemsScore = {
  __typename?: 'SubsystemsScore';
  score?: Maybe<Scalars['Float']>;
  spaces: Array<Space>;
  subsystem: Subsystem;
  systemType: SystemType;
};

export type SubsystemsScoresBySpaceInput = {
  dataFilters: DataQueryFiltersInput;
  space: SpaceInput;
  systemTypes: Array<SystemType>;
};

export type System = {
  __typename?: 'System';
  id: Scalars['String'];
  name: Scalars['String'];
  type: SystemType;
};

export type SystemNode = {
  __typename?: 'SystemNode';
  id: Scalars['String'];
  name: Scalars['String'];
  type: SystemType;
};

export enum SystemType {
  AirConditioning = 'AIR_CONDITIONING',
  ColdDistribution = 'COLD_DISTRIBUTION',
  ColdGeneration = 'COLD_GENERATION',
  DomesticHotWaterInstallation = 'DOMESTIC_HOT_WATER_INSTALLATION',
  FireProtectionSystem = 'FIRE_PROTECTION_SYSTEM',
  HeatColdDistribution = 'HEAT_COLD_DISTRIBUTION',
  HeatColdGeneration = 'HEAT_COLD_GENERATION',
  HeatDistribution = 'HEAT_DISTRIBUTION',
  HeatGeneration = 'HEAT_GENERATION',
  IndividualInstallations = 'INDIVIDUAL_INSTALLATIONS',
  RoomControl = 'ROOM_CONTROL',
  SwitchCabinet = 'SWITCH_CABINET'
}

export type SystemsScoresBySpaceInput = {
  dataFilters: DataQueryFiltersInput;
  space: SpaceInput;
};

export type TelemetryRecord = {
  __typename?: 'TelemetryRecord';
  utcTimeMeasured: Scalars['DateTime'];
  valueCount: Scalars['Float'];
  valueDouble: Scalars['Float'];
  valueMax: Scalars['Float'];
  valueMin: Scalars['Float'];
  valueString?: Maybe<Scalars['String']>;
};

export type Temperature = {
  __typename?: 'Temperature';
  unit: Scalars['String'];
  value: Scalars['Float'];
};

export type TestError = {
  __typename?: 'TestError';
  errorCode: Scalars['String'];
};

export type TimeAndAggregationParameter = {
  __typename?: 'TimeAndAggregationParameter';
  aggregationIntervalActive: AggregationIntervals;
  aggregationIntervalDefault: AggregationIntervals;
  aggregationIntervalsList?: Maybe<Array<AggregationIntervals>>;
  aggregationType?: Maybe<AggregationTypes>;
  deviceAndCapabilityInfos?: Maybe<Array<Maybe<DeviceAndCapabilityInfo>>>;
};

export enum TimeSpans {
  Last_1Hour = 'LAST_1_HOUR',
  Last_2Hour = 'LAST_2_HOUR',
  Last_4Hour = 'LAST_4_HOUR',
  Last_7Day = 'LAST_7_DAY',
  Last_12Hour = 'LAST_12_HOUR',
  Last_15Min = 'LAST_15_MIN',
  Last_24Hour = 'LAST_24_HOUR',
  Last_30Day = 'LAST_30_DAY',
  Last_30Min = 'LAST_30_MIN',
  Last_90Day = 'LAST_90_DAY'
}

export type TimeWindow = {
  __typename?: 'TimeWindow';
  cypherCode: Scalars['String'];
  id: Scalars['UUID'];
  minutes: Scalars['Int'];
  name: Scalars['String'];
  objectClassType: Scalars['String'];
  objectName: Scalars['String'];
  tenantId?: Maybe<Scalars['UUID']>;
  useGapFill: Scalars['Boolean'];
  windowType: TimeWindowType;
};

export enum TimeWindowType {
  Sliding = 'SLIDING',
  Tumbeling = 'TUMBELING'
}

export type TimeseriesDataBySpaceAndSensorResult = {
  __typename?: 'TimeseriesDataBySpaceAndSensorResult';
  aggregationIntervalActive?: Maybe<AggregationIntervals>;
  spaceTimeseriesInfos: Array<SpaceTimeseriesInfo>;
};

export type TimeseriesDataBySpaceResult = {
  __typename?: 'TimeseriesDataBySpaceResult';
  aggregationIntervalActive: AggregationIntervals;
  timeseriesInfos: Array<TimeseriesInfo>;
};

export type TimeseriesDataPoint = {
  __typename?: 'TimeseriesDataPoint';
  mostAccurateTime: Scalars['DateTime'];
  tenantId: Scalars['UUID'];
  timeseriesId: Scalars['UUID'];
  value: Scalars['Float'];
};

export type TimeseriesIdScoreType = {
  __typename?: 'TimeseriesIdScoreType';
  externalId: Scalars['String'];
  scoreType: ScoreType;
  timeseriesId: Scalars['UUID'];
};

export type TimeseriesInfo = {
  __typename?: 'TimeseriesInfo';
  data: Array<AggregatedData>;
  timeseriesId: Scalars['UUID'];
};

export type TimeseriesQueryFiltersInput = {
  limit?: InputMaybe<Scalars['Int']>;
  readFromUtc?: InputMaybe<Scalars['DateTime']>;
  readUntilUtc?: InputMaybe<Scalars['DateTime']>;
  timeseriesIds: Array<Scalars['UUID']>;
};

export type TimeseriesTelemetryRecord = {
  __typename?: 'TimeseriesTelemetryRecord';
  timestamp: Scalars['DateTime'];
  value: Scalars['Float'];
  valueCount: Scalars['Float'];
  valueMax: Scalars['Float'];
  valueMin: Scalars['Float'];
};

export type TimeseriesTimeAndAggregationParameter = {
  __typename?: 'TimeseriesTimeAndAggregationParameter';
  aggregationIntervalActive: AggregationIntervals;
  aggregationIntervalDefault: AggregationIntervals;
  aggregationIntervalsList?: Maybe<Array<AggregationIntervals>>;
  aggregationType?: Maybe<AggregationTypes>;
  timeseriesInfos?: Maybe<Array<Maybe<DataSchema_TimeseriesInfo>>>;
};

export type Unit = {
  __typename?: 'Unit';
  deviceModelCapabilities: Array<DeviceModelCapability>;
  id: Scalars['UUID'];
  isGlobal?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tenantId?: Maybe<Scalars['UUID']>;
  unitSymbol?: Maybe<Scalars['String']>;
  uris?: Maybe<Array<Scalars['String']>>;
};


export type UnitDeviceModelCapabilitiesArgs = {
  order?: InputMaybe<Array<DeviceModelCapabilitySortInput>>;
  where?: InputMaybe<DeviceModelCapabilityFilterInput>;
};

export type UnitCreateType = {
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  /** MinLength=0, MaxLength=250 */
  unitSymbol?: InputMaybe<Scalars['String']>;
  uris?: InputMaybe<Array<Scalars['String']>>;
};

export type UnitFilterInput = {
  and?: InputMaybe<Array<UnitFilterInput>>;
  deviceModelCapabilities?: InputMaybe<ListFilterInputTypeOfDeviceModelCapabilityFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<UnitFilterInput>>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  unitId?: InputMaybe<UuidOperationFilterInput>;
  unitSymbol?: InputMaybe<StringOperationFilterInput>;
  uris?: InputMaybe<ListStringOperationFilterInput>;
};

export type UnitSetNullInputType = {
  deviceModelCapabilities?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
  unitSymbol?: InputMaybe<Scalars['Boolean']>;
  uris?: InputMaybe<Scalars['Boolean']>;
};

export type UnitSortInput = {
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  unitId?: InputMaybe<SortEnumType>;
  unitSymbol?: InputMaybe<SortEnumType>;
};

export type UnitUpdateType = {
  id: Scalars['UUID'];
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=250 */
  unitSymbol?: InputMaybe<Scalars['String']>;
  uris?: InputMaybe<Array<Scalars['String']>>;
};

/** A segment of a collection. */
export type UnitsCollectionSegment = {
  __typename?: 'UnitsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Unit>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['UUID'];
  lastName: Scalars['String'];
  roles?: Maybe<Array<Role>>;
};

export type UserBaseInput = {
  /** Email address of the person */
  email: Scalars['String'];
  /** Full name of the person */
  fullName: Scalars['String'];
};

export type UuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']>;
  gt?: InputMaybe<Scalars['UUID']>;
  gte?: InputMaybe<Scalars['UUID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  lt?: InputMaybe<Scalars['UUID']>;
  lte?: InputMaybe<Scalars['UUID']>;
  neq?: InputMaybe<Scalars['UUID']>;
  ngt?: InputMaybe<Scalars['UUID']>;
  ngte?: InputMaybe<Scalars['UUID']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  nlt?: InputMaybe<Scalars['UUID']>;
  nlte?: InputMaybe<Scalars['UUID']>;
};

export type ValueEntity = {
  __typename?: 'ValueEntity';
  value?: Maybe<Scalars['String']>;
};

export type ValueEntityFilterInput = {
  and?: InputMaybe<Array<ValueEntityFilterInput>>;
  or?: InputMaybe<Array<ValueEntityFilterInput>>;
  value?: InputMaybe<StringOperationFilterInput>;
};

export type Variable = {
  __typename?: 'Variable';
  cypherCode: Scalars['String'];
  endiMatch: Scalars['String'];
  id: Scalars['UUID'];
  name: Scalars['String'];
  objectClassType: Scalars['String'];
  objectName: Scalars['String'];
  tenantId?: Maybe<Scalars['UUID']>;
  variableType: VariableType;
  windowType: TimeWindowType;
};

export enum VariableType {
  Cache = 'CACHE',
  Stream = 'STREAM',
  StreamGroupWindow = 'STREAM_GROUP_WINDOW',
  StreamWindow = 'STREAM_WINDOW'
}

/** Weather information for building location */
export type Weather = {
  __typename?: 'Weather';
  building?: Maybe<Building>;
  buildingId: Scalars['UUID'];
  iceProbability: Scalars['Int'];
  iconCode: Scalars['Int'];
  lastUpdated: Scalars['DateTime'];
  phrase: Scalars['String'];
  rainProbability: Scalars['Int'];
  snowProbability: Scalars['Int'];
  temperatureUnit: Scalars['String'];
  temperatureValue: Scalars['Float'];
  weatherId: Scalars['UUID'];
};

export type WeatherFilterInput = {
  and?: InputMaybe<Array<WeatherFilterInput>>;
  building?: InputMaybe<BuildingFilterInput>;
  buildingId?: InputMaybe<UuidOperationFilterInput>;
  iceProbability?: InputMaybe<IntOperationFilterInput>;
  iconCode?: InputMaybe<IntOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<WeatherFilterInput>>;
  phrase?: InputMaybe<StringOperationFilterInput>;
  rainProbability?: InputMaybe<IntOperationFilterInput>;
  snowProbability?: InputMaybe<IntOperationFilterInput>;
  temperatureUnit?: InputMaybe<StringOperationFilterInput>;
  temperatureValue?: InputMaybe<FloatOperationFilterInput>;
  weatherId?: InputMaybe<UuidOperationFilterInput>;
};

export type WeatherSortInput = {
  building?: InputMaybe<BuildingSortInput>;
  buildingId?: InputMaybe<SortEnumType>;
  iceProbability?: InputMaybe<SortEnumType>;
  iconCode?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  phrase?: InputMaybe<SortEnumType>;
  rainProbability?: InputMaybe<SortEnumType>;
  snowProbability?: InputMaybe<SortEnumType>;
  temperatureUnit?: InputMaybe<SortEnumType>;
  temperatureValue?: InputMaybe<SortEnumType>;
  weatherId?: InputMaybe<SortEnumType>;
};

/** I am a generated type! */
export type Zone = {
  __typename?: 'Zone';
  description?: Maybe<Scalars['String']>;
  dimension?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  image?: Maybe<Image>;
  imageId?: Maybe<Scalars['UUID']>;
  inverseParentZone: Array<Zone>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  mappingDeviceZones: Array<MappingDeviceZone>;
  name: Scalars['String'];
  parentZone?: Maybe<Zone>;
  parentZoneId?: Maybe<Scalars['UUID']>;
  storey: Storey;
  storeyId: Scalars['UUID'];
  tenantId?: Maybe<Scalars['UUID']>;
};


/** I am a generated type! */
export type ZoneInverseParentZoneArgs = {
  order?: InputMaybe<Array<ZoneSortInput>>;
  where?: InputMaybe<ZoneFilterInput>;
};


/** I am a generated type! */
export type ZoneMappingDeviceZonesArgs = {
  order?: InputMaybe<Array<MappingDeviceZoneSortInput>>;
  where?: InputMaybe<MappingDeviceZoneFilterInput>;
};

/** A segment of a collection. */
export type ZoneAndBuildingFilterTableCollectionSegment = {
  __typename?: 'ZoneAndBuildingFilterTableCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<PlacementFilterRow>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type ZoneCreateType = {
  description?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  dimension?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=250 */
  name: Scalars['String'];
  parentZoneId?: InputMaybe<Scalars['UUID']>;
  storeyId: Scalars['UUID'];
};

export type ZoneFilterInput = {
  and?: InputMaybe<Array<ZoneFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  dimension?: InputMaybe<StringOperationFilterInput>;
  image?: InputMaybe<ImageFilterInput>;
  imageId?: InputMaybe<UuidOperationFilterInput>;
  inverseParentZone?: InputMaybe<ListFilterInputTypeOfZoneFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  mappingDeviceZones?: InputMaybe<ListFilterInputTypeOfMappingDeviceZoneFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ZoneFilterInput>>;
  parentZone?: InputMaybe<ZoneFilterInput>;
  parentZoneId?: InputMaybe<UuidOperationFilterInput>;
  storey?: InputMaybe<StoreyFilterInput>;
  storeyId?: InputMaybe<UuidOperationFilterInput>;
  tenantId?: InputMaybe<UuidOperationFilterInput>;
  zoneId?: InputMaybe<UuidOperationFilterInput>;
};

/** A segment of a collection. */
export type ZoneFilterTableCollectionSegment = {
  __typename?: 'ZoneFilterTableCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<PlacementFilterRow>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type ZoneSetNullInputType = {
  description?: InputMaybe<Scalars['Boolean']>;
  dimension?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<Scalars['Boolean']>;
  imageId?: InputMaybe<Scalars['Boolean']>;
  inverseParentZone?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  mappingDeviceZones?: InputMaybe<Scalars['Boolean']>;
  parentZone?: InputMaybe<Scalars['Boolean']>;
  parentZoneId?: InputMaybe<Scalars['Boolean']>;
  storey?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['Boolean']>;
};

export type ZoneSortInput = {
  description?: InputMaybe<SortEnumType>;
  dimension?: InputMaybe<SortEnumType>;
  image?: InputMaybe<ImageSortInput>;
  imageId?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  parentZone?: InputMaybe<ZoneSortInput>;
  parentZoneId?: InputMaybe<SortEnumType>;
  storey?: InputMaybe<StoreySortInput>;
  storeyId?: InputMaybe<SortEnumType>;
  tenantId?: InputMaybe<SortEnumType>;
  zoneId?: InputMaybe<SortEnumType>;
};

export type ZoneUpdateType = {
  description?: InputMaybe<Scalars['String']>;
  /** MinLength=0, MaxLength=100 */
  dimension?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  imageId?: InputMaybe<Scalars['UUID']>;
  /** MinLength=0, MaxLength=250 */
  name?: InputMaybe<Scalars['String']>;
  parentZoneId?: InputMaybe<Scalars['UUID']>;
  storeyId?: InputMaybe<Scalars['UUID']>;
};

/** A segment of a collection. */
export type ZonesCollectionSegment = {
  __typename?: 'ZonesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Zone>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type BuildingInformationSchema_InstallationPart = {
  __typename?: 'buildingInformationSchema_InstallationPart';
  id: Scalars['String'];
  name: Scalars['String'];
  timeseriesId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type BuildingInformationSchema_NodeParents = {
  __typename?: 'buildingInformationSchema_NodeParents';
  equipment?: Maybe<Node>;
  nodeId?: Maybe<Scalars['String']>;
  sensor?: Maybe<Node>;
  spaces: Array<Space>;
  subsystem?: Maybe<Node>;
  system?: Maybe<SystemNode>;
};

export type BuildingInformationSchema_System = {
  __typename?: 'buildingInformationSchema_System';
  id: Scalars['String'];
  name: Scalars['String'];
  spaces: Array<Space>;
  subSystems: Array<SubSystem>;
  type: SystemType;
};

export type DataSchema_TimeseriesInfo = {
  __typename?: 'dataSchema_TimeseriesInfo';
  telemetryRecords?: Maybe<Array<Maybe<TimeseriesTelemetryRecord>>>;
  tenantId?: Maybe<Scalars['UUID']>;
  timeseriesId: Scalars['UUID'];
};

export type RuleEngineSchema_Rule = {
  __typename?: 'ruleEngineSchema_Rule';
  alarmType: AlarmType;
  description: Scalars['String'];
  externalId: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  severity: Severity;
  timeseriesId: Scalars['UUID'];
};

export type GetGatewayWithChildrenByIdQueryVariables = Exact<{
  deviceId: Scalars['UUID'];
}>;


export type GetGatewayWithChildrenByIdQuery = { __typename?: 'Query', device?: { __typename?: 'Device', id: string, name: string, deviceIdentifier?: string | null, deviceModel: { __typename?: 'DeviceModel', id: string, name: string, deviceIdentifierFieldSelector?: string | null, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string, fieldSelector?: string | null, capability: { __typename?: 'Capability', id: string, name: string } }> }, inverseParentDevice: Array<{ __typename?: 'Device', id: string, name: string, deviceIdentifier?: string | null, deviceModel: { __typename?: 'DeviceModel', id: string, name: string, deviceIdentifierFieldSelector?: string | null, deviceModelCapabilities: Array<{ __typename?: 'DeviceModelCapability', id: string, fieldSelector?: string | null, capability: { __typename?: 'Capability', id: string, name: string } }> } }> } | null };

export type AllGatewaysQueryVariables = Exact<{ [key: string]: never; }>;


export type AllGatewaysQuery = { __typename?: 'Query', devices?: { __typename?: 'DevicesCollectionSegment', items?: Array<{ __typename?: 'Device', id: string, name: string, deviceIdentifier?: string | null, connectionState: ConnectionStates }> | null } | null };

export type RegenerateSharedAccessSignatureMutationVariables = Exact<{
  gatewayId: Scalars['UUID'];
  expirySeconds: Scalars['Int'];
}>;


export type RegenerateSharedAccessSignatureMutation = { __typename?: 'Mutation', regenerateGatewayDeviceSharedAccessSignature: { __typename?: 'SharedAccessSignatureData', sharedAccessSignature: string } };


export const GetGatewayWithChildrenByIdDocument = gql`
    query GetGatewayWithChildrenById($deviceId: UUID!) {
  device(id: $deviceId) {
    id
    name
    deviceIdentifier
    deviceModel {
      id
      name
      deviceIdentifierFieldSelector
      deviceModelCapabilities {
        id
        fieldSelector
        capability {
          id
          name
        }
      }
    }
    inverseParentDevice {
      id
      name
      deviceIdentifier
      deviceModel {
        id
        name
        deviceIdentifierFieldSelector
        deviceModelCapabilities {
          id
          fieldSelector
          capability {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetGatewayWithChildrenByIdQuery__
 *
 * To run a query within a React component, call `useGetGatewayWithChildrenByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGatewayWithChildrenByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGatewayWithChildrenByIdQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useGetGatewayWithChildrenByIdQuery(baseOptions: Apollo.QueryHookOptions<GetGatewayWithChildrenByIdQuery, GetGatewayWithChildrenByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGatewayWithChildrenByIdQuery, GetGatewayWithChildrenByIdQueryVariables>(GetGatewayWithChildrenByIdDocument, options);
      }
export function useGetGatewayWithChildrenByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGatewayWithChildrenByIdQuery, GetGatewayWithChildrenByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGatewayWithChildrenByIdQuery, GetGatewayWithChildrenByIdQueryVariables>(GetGatewayWithChildrenByIdDocument, options);
        }
export type GetGatewayWithChildrenByIdQueryHookResult = ReturnType<typeof useGetGatewayWithChildrenByIdQuery>;
export type GetGatewayWithChildrenByIdLazyQueryHookResult = ReturnType<typeof useGetGatewayWithChildrenByIdLazyQuery>;
export type GetGatewayWithChildrenByIdQueryResult = Apollo.QueryResult<GetGatewayWithChildrenByIdQuery, GetGatewayWithChildrenByIdQueryVariables>;
export const AllGatewaysDocument = gql`
    query AllGateways {
  devices(
    where: {and: [{deviceModel: {deviceType: {isGateway: {eq: true}}}}]}
    order: {name: ASC}
  ) {
    items {
      id
      name
      deviceIdentifier
      connectionState
    }
  }
}
    `;

/**
 * __useAllGatewaysQuery__
 *
 * To run a query within a React component, call `useAllGatewaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllGatewaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllGatewaysQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllGatewaysQuery(baseOptions?: Apollo.QueryHookOptions<AllGatewaysQuery, AllGatewaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllGatewaysQuery, AllGatewaysQueryVariables>(AllGatewaysDocument, options);
      }
export function useAllGatewaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllGatewaysQuery, AllGatewaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllGatewaysQuery, AllGatewaysQueryVariables>(AllGatewaysDocument, options);
        }
export type AllGatewaysQueryHookResult = ReturnType<typeof useAllGatewaysQuery>;
export type AllGatewaysLazyQueryHookResult = ReturnType<typeof useAllGatewaysLazyQuery>;
export type AllGatewaysQueryResult = Apollo.QueryResult<AllGatewaysQuery, AllGatewaysQueryVariables>;
export const RegenerateSharedAccessSignatureDocument = gql`
    mutation RegenerateSharedAccessSignature($gatewayId: UUID!, $expirySeconds: Int!) {
  regenerateGatewayDeviceSharedAccessSignature(
    deviceId: $gatewayId
    expirySeconds: $expirySeconds
  ) {
    sharedAccessSignature
  }
}
    `;
export type RegenerateSharedAccessSignatureMutationFn = Apollo.MutationFunction<RegenerateSharedAccessSignatureMutation, RegenerateSharedAccessSignatureMutationVariables>;

/**
 * __useRegenerateSharedAccessSignatureMutation__
 *
 * To run a mutation, you first call `useRegenerateSharedAccessSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateSharedAccessSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateSharedAccessSignatureMutation, { data, loading, error }] = useRegenerateSharedAccessSignatureMutation({
 *   variables: {
 *      gatewayId: // value for 'gatewayId'
 *      expirySeconds: // value for 'expirySeconds'
 *   },
 * });
 */
export function useRegenerateSharedAccessSignatureMutation(baseOptions?: Apollo.MutationHookOptions<RegenerateSharedAccessSignatureMutation, RegenerateSharedAccessSignatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegenerateSharedAccessSignatureMutation, RegenerateSharedAccessSignatureMutationVariables>(RegenerateSharedAccessSignatureDocument, options);
      }
export type RegenerateSharedAccessSignatureMutationHookResult = ReturnType<typeof useRegenerateSharedAccessSignatureMutation>;
export type RegenerateSharedAccessSignatureMutationResult = Apollo.MutationResult<RegenerateSharedAccessSignatureMutation>;
export type RegenerateSharedAccessSignatureMutationOptions = Apollo.BaseMutationOptions<RegenerateSharedAccessSignatureMutation, RegenerateSharedAccessSignatureMutationVariables>;